import http from "./httpService";
import { getDataJwt } from "./httpService";

export function getCalificaciones( docente ) {
    let apiEndPoint = "/Calificaciones";

    if (docente > 0)
        apiEndPoint = `${apiEndPoint}/Docente/${docente}`;

    return http.get(apiEndPoint);
}
export function deleteAnularActa(id) {
    let apiEndPoint = `/Calificaciones/deleteActa/${id}`;

    return http.get(apiEndPoint);
}
export function getPeriodosEvaActivos() {
    let apiEndPointLC = "/PeriodosEvaluativos/activos";

    return http.get(apiEndPointLC);
}
export function getCortesEvaluados() {
    let apiEndPointLC = "/CortesEvaluativos/activos";

    return http.get(apiEndPointLC);
}
export function getDocentesActivos( docente) {
    let apiEndPointLC = "/Docentes/habilitados";

    if (docente > 0)
        apiEndPointLC = `${apiEndPointLC}/${docente}`;

    return http.get(apiEndPointLC);
}
export function getCursosDocente(docente) {
    let apiEndPoint = `/Cursos/docente`;

    if (docente > 0)
        apiEndPoint = `${apiEndPoint}/${docente}`;

    return http.get(apiEndPoint);
}
export function getDisciplinasCursoDocente(docente, curso) {
    let apiEndPoint = `/disciplinas/${docente}/${curso}/false`;

    return http.get(apiEndPoint);
}
export function getDetalleActa(acta, disciplina) {
    let apiEndPoint = `/Calificaciones/acta/${acta}/${disciplina}`;

    return http.get(apiEndPoint);
}
export function putDetalleActa(id, nota) {
    const { usuario } = getDataJwt();
    const acta = { "id": id,
                   "nota": parseInt(nota),
                   "usuario": parseInt(usuario) }

    let apiEndPoint = `/Calificaciones/update`;

    return http.post(apiEndPoint, acta).then(resp => resp).catch(err => err);
}
export function deleteAlumnoActa(id, matricula, disciplina) {
    const acta = { "acta": id,
                   "matricula": matricula,
                   "disciplina": disciplina }

    let apiEndPoint = `/Calificaciones/delete`;

    return http.post(apiEndPoint, acta);
}
export function getResumenCalificaciones( curso, cortes ) {
    let apiEndPoint = `/calificaciones/ResumenCalificaciones`;

    const data = { "curso": curso,
                   "cortes": cortes };
    
    return http.post( apiEndPoint, data );
}
export function postAddActa(data) {
    let { periodo, usuario } = getDataJwt();
    let disciplina = data.disciplina;
    let alumnos = data.alumnos;
    const acta = { id: data.acta,
                   idPeriodo: parseInt(periodo),
                   idCorteEvaluativo: data.corte,
                   idProfesor: data.docente,
                   idSeccion: data.curso,
                   cerrado: false,
                   accion: 0,
                   usuario: parseInt(usuario) }

    return http.post(`/Calificaciones`, { acta, disciplina, alumnos })
               .then(resp => resp)
               .catch(err => err);
}