import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { OpenModalObser, 
         startAddObservaciones, 
         startCursoGuiado, 
         startDetalleObservacaciones } from '../state/actions/actionsCalificaciones';

const stateInitial = { 
    corte : 0,
    docente : 0,
    curso : 0,
    alumnos: []
}
const validationSchema = Yup.object({
            curso: Yup.number().required().min(1, "Seleccione el curso"),
            docente: Yup.number().required().min(1, "Seleccione el Docente"),
            corte: Yup.number().required().min(1, "Seleccione el Corte"),
         });

export const useObservaciones = ( initialState = stateInitial ) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initialState);

    const handleInputChange = ( { target } ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : target.value
        });
    }
    const { cursos, openModalObser } = useSelector( state => state.calificaciones );
    
    const [confirmOpen, setConfirmOpen] = useState( false );
    
    useEffect(() => {
        const cargarCursos = async () => {
            if ( formValues.docente > 0 )
                dispatch( startCursoGuiado(formValues.docente));
        }
        cargarCursos();
    }, [ formValues.docente ]);

    useEffect(() => {
        const cargarDetalle = async () => {
            if ( formValues.corte > 0 && formValues.docente > 0 && formValues.curso > 0 ){
                const acta = { corte: formValues.corte,
                               docente: formValues.docente,
                               curso: formValues.curso };
                               
                dispatch( startDetalleObservacaciones( acta ))
            }
        }
        cargarDetalle();
    }, [ formValues.curso ]);

    const GuardarActa = async e => {
        let data = { corte: parseInt( formValues.corte ),
                     docente: parseInt( formValues.docente ),  
                     curso: parseInt( formValues.curso ),
                     alumnos: formValues.alumnos
                    };
        
        dispatch( startAddObservaciones( data ) );
        handleInputChange({ target: { 'name': 'alumnos', 'value': [] }});
    }
    
    const ValidarActa = () => {
        try {
            return validationSchema.validateSync(formValues);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }
    
    const UpdateOpenModal = ( valor ) => dispatch( OpenModalObser( valor ));

    return { UpdateOpenModal, openModalObser, confirmOpen, setConfirmOpen,
        cursos, formValues, handleInputChange, GuardarActa, ValidarActa };
}