import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmDialog from '../../UI/ConfirmDialog'
import { UIBotonera } from '../../UI/UIBotoneras'

import { usePlanificacionDetalle } from '../../../hooks/usePlanificacionDiariaDetalle'

export const AddDetallePlaniDiaria = ( { data, valid }) => {
	const [confirmOpen, setConfirmOpen] = useState(false);
	const { LoadActividad, AddNewActividad, AddDetalle, ValidarActa, formValues, actaPlaniDiaria, categorias, handleInputChange, actividades, DelActividad } = usePlanificacionDetalle(); 

	const { categoria, titulo, actividad } = formValues;
    return (
        <Grid container style={{marginTop:0}} >
					<Grid item xs={12} md={6} className="margenesGrid">
						Categoria
						<TextField size='small'
                                    select
                                   fullWidth
                                   value={categoria}
                                   name="categoria"
                                   onChange={ e => handleInputChange( e )}>
                            {categorias.map((option) => (
								<MenuItem key={option.value} 
										  value={option.value}>
									{option.label}
								</MenuItem>
                            ))} 
                        </TextField>
					</Grid>
					<Grid item xs={12} md={6} className="margenesGrid">
						Titulo
						<TextField  size='small'
									fullWidth
									multiline
									minRows={1}
									value={titulo}
                                    name="titulo"
									onChange={ e => handleInputChange( e)}/>
					</Grid>
					<Grid item xs={actaPlaniDiaria.id === 0 ? 12 : 11} className="margenesGrid">
						<TextField	size="small"
									fullWidth
									multiline
									minRows={5}
									maxRows={5}
									label="Actividad"
									value={actividad}
                                    name="actividad"
                                    onChange={ e => handleInputChange( e)}/>
					</Grid>
					<Grid item xs={actaPlaniDiaria.id === 0 ? 1 : 12} 
						className="margenesGrid subtitulo" 
						style={{textAlign:'center', alignSelf:'center'}}>

						{ actaPlaniDiaria.id === 0 
							? <Tooltip title="Agregar">
									<IconButton	edge="start"
												color="inherit"
												aria-label="menu"
												onClick={ ()=> setConfirmOpen(valid) }>
										<AddCircleIcon fontSize="large" />
									</IconButton>
								</Tooltip>
							: <UIBotonera lista = { actividades }
							 				functionLoad = { LoadActividad }
							 				functionAddNew = { AddNewActividad }
							 				functionAdd = { AddDetalle }
											functionDel = { DelActividad }
							 				titleButton = "Actividades"
							 				opcion = {3}/>
						}
					</Grid>
			<ConfirmDialog 
                title = {`¿Confirma agregar detalle a la Planificacion Diaria?`} 
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { () => AddDetalle(data) } />
        </Grid>
    );
};