import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { startAddUnidad,
         AddNewUnit,
         LoadUnit,
         startDelUnidad } from '../state/actions/actionsPlanificacion';


const stateInitial = { planificacionMensualId : 0, 
                       id : 0, 
                       unidad : '', 
                       competenciaGrado : '', 
                       ejeTranversal : '', 
                        observaciones : ''};

export const usePlanificacionDetalle = () => {
    const dispatch = useDispatch();
    const {actaActiva } = useSelector( state => state.planificacion );
    const [formValues, setFormValues] = useState(stateInitial);

    const validationSchemaUND = Yup.object({
        ejeTranversal: Yup.string().required("Ingrese el Eje tranversal"),
        competenciaGrado: Yup.string().required("Ingrese la Competencia de Grado"),
        unidad: Yup.string().required("Ingrese la Unidad"),
        planificacionMensualId: Yup.number().min(1, "Numero de acta invalida").required() 
    });

       
    const handleInputChange = ( { target } ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : target.value
        });
    }

    useEffect(() => {
        const actualizarPlanificacionId = () => {
            setFormValues({...actaActiva.unidadActiva});
        }
        actualizarPlanificacionId();
    }, [ actaActiva.unidadActiva ]);
    ////// MANEJO DE UNIDADES
    const ValidarUnidad = (data) => {
        try {
            return validationSchemaUND.validateSync(data);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }
    const AddUnidad = () => {       
        const data = { ...formValues,
                       planificacionMensualId : actaActiva.id,
                       id : actaActiva.unidadActiva.id }

        if (ValidarUnidad(data))
            dispatch( startAddUnidad( data, (data.id > 0) ));    
    }
    const AddNewUnidad = () => {
        dispatch( AddNewUnit() )
    }
    const LoadUnidad = (id) => {
        dispatch( LoadUnit( id ) );
    }
    const DelUnidad = () => {       
        if (formValues.id > 0)
            dispatch( startDelUnidad( formValues.id ));    
    }

    return { LoadUnidad, AddNewUnidad, AddUnidad,
             formValues, handleInputChange, actaActiva, DelUnidad };
}