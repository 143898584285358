import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { startVerificarAcceso } from '../../state/actions/auth';
import './login.css';

export const Login = ( props ) => {
    const { error } = useSelector( state => state.auth )
    const dispatch = useDispatch();
    
    const [usuario, setUsuario] = useState( "" );
    const [password, setPassword] = useState( "" );
    const [habilitado, setHabilitado] = useState( false );
    
    useEffect(() => {
        const habilitarBoton = () => {
            setHabilitado( (usuario === '' || password === '') );
        }
        habilitarBoton();
    }, [password, usuario])

    const VerificarAcceso = () => {
        dispatch( startVerificarAcceso( { usuario, password } ));
    };

    return (
        <div className='lgRoot'>
            <div className='lgImage'/>
            <div className='lgPaper'>
                <div className='lgTitulo'>
                    <h3>SISTEMA DE INFORMACION ACADEMICA Y FINANCIERA</h3>
                </div>
                <div className='lgAvatar'>
                    <Typography component="h1" variant="h5" >
                        Inicio Sesion
                    </Typography>
                </div>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="usuario"
                    label="Usuario"
                    name="usuario"
                    value={ usuario }
                    onChange={ ( e ) => setUsuario(e.target.value) }
                    autoFocus/>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={ password }
                    autoComplete="current-password"
                    onChange={ ( e ) => setPassword( e.target.value ) }/>
                { error && <Typography color = 'error'>
                               Error de Inicio de sesion, usuario y/o contraseña invalidos. 
                           </Typography> }
                <Button
                    type="button"
                    fullWidth
                    disabled={ habilitado }
                    variant="contained"
                    color="primary"
                    className = 'submit'
                    size = "large"
                    style={{marginTop: 30}}
                    onClick = { VerificarAcceso } >
                    Ingresar al Sistema
                </Button>
            </div>
        </div>
    );
}