import http from "./httpService";
import { getDataJwt } from "./httpService";
/////UNIDAD DIDACTICA
export function getFamiliaValores( periodo ) {
    const apiEndPoint = `/Planificacion/FamiliaValores/${ periodo }`;

    return http.get(apiEndPoint);
}
export function getPrincipiosValores( familiaValor ) {
    const apiEndPoint = `/Planificacion/PrincipiosValores`;

    return http.post(apiEndPoint, familiaValor )
               .then(resp => resp)
               .catch(err => err);
}
export function getImprimirPlanificacionMensual( id ) {
    return http.get(`/Informes/PlanificacionMensual/${ id }` );
}
export function getImprimirPlanificacionSemanal( id ) {
    return http.get(`/Informes/PlanificacionSemanal/${ id }`);
}
export function getImprimirPlanificacionDiario( id ) {
    return http.get(`/Informes/PlanificacionDiaria/${ id }`);
}

export function getContenidosUnidad( id ) {
    const apiEndPoint = `/Planificacion/getContenidoUnidad/${ id }`;

    return http.get(apiEndPoint);
}
export function getUnidadDidactica( id ) {
    const apiEndPoint = `/Planificacion/${ id }`;

    return http.get(apiEndPoint);
}
export function getUnidadesDidacticas( docente ) {
    const apiEndPoint = `/Planificacion${ docente > 0 ? `/Docente/${docente}` : ""}`;

    return http.get(apiEndPoint);
}

export function postContenidoUnidad( data ) {
    let apiEndPoint = `/Planificacion/ContenidoUnidad`;

    return http.post(apiEndPoint, data )
               .then(resp => resp)
               .catch(err => err);
}
export function deleteContenidoUnidad( id ) {
    let apiEndPoint = `/Planificacion/ContenidoUnidad/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function postIndicadorLogro( data ) {
    let apiEndPoint = `/Planificacion/IndicadorLogro`;

    return http.post(apiEndPoint, data )
               .then(resp => resp)
               .catch(err => err);
}
export function deleteIndicadorLogro( id ) {
    let apiEndPoint = `/Planificacion/IndicadorLogro/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function postCerrarActaPM( acta ) {
    const { usuario } = getDataJwt();
    
    const data = { "acta" : parseInt(acta),
                   "usuario" : parseInt(usuario) };

    return http.post(`/Planificacion/CerrarActa`, data )
               .then(resp => resp)
               .catch(err => err);
}
export function postCerrarActaPS( acta ) {
    const { usuario } = getDataJwt();
    
    const data = { "acta" : parseInt(acta),
                   "usuario" : parseInt(usuario) };

    return http.post(`/PlanificacionSemanal/CerrarActa`, data )
               .then(resp => resp)
               .catch(err => err);
}
export function postPlanificacionMensual( data ) {
    const { periodo, usuario } = getDataJwt();
    
    data.grado = parseInt(data.grado);
    data.periodo = parseInt(periodo);
    data.usuario = parseInt(usuario);

    return http.post(`/Planificacion/UnidadDidactica`, data ).then(resp => resp)
                                          .catch(err => err);

    /*return http.post(apiEndPoint, { "unidadDidactica": data, 
                                    "indicadorLogro": { "indicadorLogro": indicador }})
               .then(resp => resp)
               .catch(err => err);*/
}
export function postaddUnidad( data ) {
    return http.post(`/Planificacion/AddUnidad`, data )
               .then(resp => resp)
               .catch(err => err);
}
export function postAddUnidadPlanificacionMensual( data ) {
    const { usuario } = getDataJwt();
    
    data.usuario = parseInt(usuario);

    const apiEndPoint = `/Planificacion/AddUnidad`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function postAddTemaPlanificacionMensual( data ) {
    //const { usuario } = getDataJwt();
    
    //data.usuario = parseInt(usuario);

    const apiEndPoint = `/Planificacion/AddTema`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function postPlanificacionMensualRevision( data ) {
    const { usuario } = getDataJwt();
    
    data.usuario = parseInt(usuario);

    const apiEndPoint = `/Planificacion/RevisionUnidadDidactica`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function deletePlanificacionMensualRevision( id ) {
    let apiEndPoint = `/Planificacion/deleteRevisionUnidadDidatica/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function updateAplicarPlanificacionMensualRevision( id ) {
    const apiEndPoint = `/Planificacion/updateRevisionUnidadDidatica/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function deleteDetallePlanificacionMensual( id ) {
    let apiEndPoint = `/Planificacion/Detalle/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function deletePlanificacionMensual( id ) {
    let apiEndPoint = `/Planificacion/Delete/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
/////PLANIFICACION SEMANAL
export function getPlanificacionSemanal( id ) {
    const apiEndPoint = `/PlanificacionSemanal/${ id }`;

    return http.get(apiEndPoint);
}
export function postPlanificacionSemanl( data ) {
    const { usuario } = getDataJwt();
    
    data.usuario = parseInt(usuario);

    let apiEndPoint = `/PlanificacionSemanal`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function postDetallePlanificacionSemanal( data ) {
    const apiEndPoint = `/PlanificacionSemanal/Detalle`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function delFechaPlanificacionSemanal( id ) {
    let apiEndPoint = `/PlanificacionSemanal/DelFecha/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function postAddFechaPlanificacionSemanal( data ) {
    const apiEndPoint = `/PlanificacionSemanal/AddFecha`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function deletePlanificacionSemanal( id ) {
    let apiEndPoint = `/PlanificacionSemanal/Delete/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}

export function deleteDetallePlanificacionSemanal( id ) {
    let apiEndPoint = `/PlanificacionSemanal/DeleteDetalle/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function getUnidadesCursos( id ) {
    const apiEndPoint = `/PlanificacionSemanal/UnidadesCursos/${ id }`;

    return http.get(apiEndPoint);
}
export function postPlanificacionSemanalRevision( data ) {
    const { usuario } = getDataJwt();
    
    data.usuario = parseInt(usuario);

    const apiEndPoint = `/PlanificacionSemanal/Revision`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function deletePlanificacionSemanalRevision( id ) {
    let apiEndPoint = `/PlanificacionSemanal/deleteRevision/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function updateAplicarPlanificacionSemanalRevision( id ) {
    const apiEndPoint = `/PlanificacionSemanal/updateRevision/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
/////PLANIFICACION DIARIA
export function getPlanificacionDiaria( id ) {
    const apiEndPoint = `/PlanificacionDiaria/${ id }`;

    return http.get(apiEndPoint);
}
export function getDetalleFecha( id ) {
    const apiEndPoint = `/PlanificacionDiaria/DetalleFecha/${ id }`;

    return http.get(apiEndPoint);
}

export function postPlanificacionDiaria( data ) {
    const { usuario } = getDataJwt();
    
    data.idUsuario = parseInt(usuario);

    const apiEndPoint = `/PlanificacionDiaria`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function postDetallePlanificacionDiaria( data ) {
    const apiEndPoint = `/PlanificacionDiaria/Detalle`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function delUnidad( id ) {
    const apiEndPoint = `/Planificacion/DelUnidad/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function delTema( id ) {
    const apiEndPoint = `/Planificacion/DelTema/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function delContenido( id ) {
    const apiEndPoint = `/PlanificacionSemanal/DelContenido/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}