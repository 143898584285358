import React, { useState } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import ConfirmDialog from '../UI/ConfirmDialog'
import { useSelector, useDispatch } from 'react-redux';
import { startDeleteCalificacion, startUpdateCalificacion } from '../../state/actions/actionsCalificaciones';
import './style.css';

export const DetalleActa = ( { disciplina } ) => {
   const dispatch = useDispatch();
   const { altoContenedor } = useSelector(state => state.auth);
   const { conceptos, alumnos, notas, indicadores, actaActiva  } = useSelector(state => state.calificaciones)

   const [confirmOpen, setConfirmOpen] = useState( false );
   const [matricula, setMatricula] = useState( 0 );
   const [filtro, setFiltro] = useState( "" );
   const [alumno, setAlumno] = useState( "" );
   let indice = 0;

   const UpdateCalificacion = (e, row, indice, v = 0, lista = false) => {
      const nota = lista ? v : parseInt(e.target.value);
      const notaMax = lista ? 3 : parseInt(e.target.max);
      
      //parseInt(nota) !== parseInt(row.nota) &&
      if ( row.id > 0 && nota >= 0 && nota <= notaMax ){
         dispatch( startUpdateCalificacion ( {...row, nota } ) );
         
         if (!lista) {
            let input = document.querySelectorAll(`[data-class="clase${indice}"]`);
            let total = 0;

            input.forEach(function (element) { total += parseInt(element.value); });
            
            document.querySelector(`#fila${indice}`).innerHTML= total;
         }
      }
      
      if ( nota > notaMax )
         e.target.value = 0
   }
   const DeleteCalificacion = () => dispatch( startDeleteCalificacion(actaActiva.id, matricula, disciplina));

   return (
      <div style={{ maxWidth: '100%' }}>
         <TableContainer style={{minHeight: `50vh`, maxHeight: `${altoContenedor - 390}px`}} >
            <Table stickyHeader size="small" >
               <TableHead>
                  <TableRow>
                     <TableCell align="left"
                                style={{ backgroundColor: '#01579b', color : 'white', width: 200 }}>
                        <Tooltip title="Filtrar alumnos en el detalle.">
                           <TextField variant="outlined" 
                                      size='small' 
                                      margin='none'
                                      fullWidth={true} 
                                      style={{paddingTop:0, paddingBottom:0, background: 'white'}}
                                      InputProps={{ startAdornment: ( <InputAdornment position="start">
                                                                         <SearchIcon />
                                                                      </InputAdornment> ) }}
                                      onKeyUp={ ( e ) => setFiltro( e.target.value ) }
                           />
                        </Tooltip> 
                     </TableCell>
                     <TableCell className="cellTotal">
                        Alumno
                     </TableCell>

                     {conceptos.map((column, key) => (
                        <Tooltip key={ Math.random()} 
                                 title={`${column.concepto}, ${column.nota} ptos.`}>
                           <TableCell style={{ backgroundColor: '#01579b', color : 'white', padding:0 }}
                                      key={ Math.random()} align="center">
                              {column.alias}
                           </TableCell>
                        </Tooltip>
                     ))}
                     
                     { !actaActiva.ei && <TableCell align="center" 
                                                    style={{ backgroundColor: '#01579b', 
                                                             color : 'white', padding:3 }}>
                                                Nota
                                         </TableCell> }
                  </TableRow>
               </TableHead>
               <TableBody >
                  { alumnos.map((row) => {
                     indice += 1;
                     let indice1 = indice;
                     let total = 0;
                     const nota = notas.filter( n => n.idMatricula === row.idMatricula );

                     return ( <TableRow key={ Math.random()} 
                                        style={{ height:'20', marginTop:'2', marginBottom:'2' }} 
                                        className= { filtro.length === 0 ? '' : !row.alumno.includes( filtro.toUpperCase() ) ? 'hide' : '' }>
                                 <TableCell colSpan={2} 
                                            className= {`${row.sexo}`} 
                                            style={{ padding:0, paddingLeft:10 }}>
                                    <Tooltip title='Quitar Alumno del Acta/Disciplina'>
                                       <IconButton edge="start" color="inherit" 
                                                   aria-label="menu" style={{ padding: 6 }} 
                                                   onClick={ () => { setAlumno( row.alumno);
                                                                     setMatricula( row.idMatricula );
                                                                     setConfirmOpen( true ); } }>
                                          <CancelIcon/>
                                       </IconButton>
                                    </Tooltip>
                                    { row.alumno }
                                 </TableCell>
                                 
                                 { nota.map((column) => {
                                    total += column.nota;
                                    return ( <TableCell key={ Math.random() } 
                                                        align="center" style={{ padding: 0 }}>
                                                { !actaActiva.ei ?
                                                   <TextField variant= "standard"
                                                         defaultValue= {`${column.nota}`}
                                                         type="number"
                                                         InputProps={{ inputProps: { 'data-class': `clase${indice1}`, 
                                                                                     max: `${column.notaMax}`, 
                                                                                     min: 0 } }}
                                                         style= {{ width: '50px', border:0 }}
                                                         size = 'small'
                                                         required= {true}
                                                         fullWidth = {true}
                                                         onBlur = { ( e ) => UpdateCalificacion( e, column, indice1 ) }/>
                                                   : <Select id="demo-simple-select"
                                                             defaultValue={column.nota}>
                                                         { indicadores.map( (indicador) => 
                                                            <MenuItem key={ Math.random() } 
                                                                      value={ indicador.valor }
                                                                      onClick={ ( e ) => UpdateCalificacion( e, column, indice1, indicador.valor, true )}
                                                                      style={{ background: `${indicador.color}`,
                                                                               color: `${indicador.letra}` }}>
                                                               { indicador.alias }
                                                               
                                                            </MenuItem>
                                                         )}
                                                      </Select>
                                                }
                                             </TableCell>)
                                             })}
                                    { !actaActiva.ei && <TableCell id={`fila${indice1}`}
                                                                   className="cellTotal">
                                                            {total}
                                                        </TableCell>
                                    }
                              </TableRow>)
                     }
                  )}
               </TableBody>
            </Table>
         </TableContainer>
         <ConfirmDialog title = {`¿Confirma quitar a (${ alumno })  de esta Acta?`} 
                        open = { confirmOpen } 
                        setOpen = { setConfirmOpen } 
                        onConfirm = { DeleteCalificacion } />
      </div>
   )
}