import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from "react-dropdown-select";
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';

import { CustomItemRenderer } from '../comunes/CustomItemRenderer'
import { getCalificaciones, getConductas, getCalificacionesReprobadas, getMejoresCalificaciones, getCortes, getCursos } from '../../servicios/informes';

import { VisorReportes } from './VisorReportes'

export const Reportes = () => {
   const { tiposReportes, grupos, tops } = useSelector( state => state.general );
   const { altoContenedor, docente } = useSelector( state => state.auth);
   const altoFrame = altoContenedor - 80;

   const [ cortes, setCortes] = useState( [] );
   const [ cursos, setCursos] = useState( [] );

   const [reporteSeleccionado, setReporteSeleccionado] = useState([]);
   const [corteSeleccionado, setCorteSeleccionado] = useState( cortes.length > 0 ? [cortes[0]] : [] );
   const [cursoSeleccionado, setCursoSeleccionado] = useState( cursos.length > 0 ? [cursos[0]] : [] );
   const [grupoSeleccionado, setGrupoSeleccionado] = useState( grupos.length > 0 ? [grupos[0]] : [] );
   const [topSeleccionado, setTopSeleccionado] = useState( tops.length > 0 ? [tops[0]] : [] );
   
   const [multiCortes, setMultiCortes] = useState( false );
   const [cargando, setCargargando] = useState( false );
   const [urlReporte, setUrlReporte] = useState( "");

   useEffect(() => {
      const Load = async () => {
         const resultCortes = await getCortes();

         if (resultCortes.status === 200)
            setCortes(resultCortes.data)

         /*const result = await getCursos(docente);

         if (result.status === 200)
            setCursos(result.data)*/
      }
      Load();
   }, []);

   useEffect(() => {
      const Load = async () => {
         let tipo = 1;
         
         if (reporteSeleccionado.length > 0)
            tipo = grupoSeleccionado[0].id

         const result = await getCursos(docente, tipo);
         if (result.status === 200)
            setCursos(result.data)
      }
      Load();
   }, [grupoSeleccionado]);

   const DefinirMultiCortes = async ( valor ) => {
      if (reporteSeleccionado.length > 0 && reporteSeleccionado[0].id === 4 ){
         const result = await getCursos(docente, 1);

         if (result.status === 200)
            setCursos(result.data)
      }

      setReporteSeleccionado( valor )

      setMultiCortes( (valor[0].id !== 2 ) )
   }

   const VerReporte = async () => {
      if (cursoSeleccionado.length > 0 && reporteSeleccionado.length > 0 && corteSeleccionado.length > 0){ 

         const curso = cursoSeleccionado[0].id;
         let arrCortes = "";

         if ( reporteSeleccionado[0].id === 1 ) {
            corteSeleccionado.map( x => arrCortes += `${x.id},`);
            
            setCargargando( true );
            const data = await getCalificaciones(corteSeleccionado, curso);

            if (data.status === 200)
               setUrlReporte( `data:application/pdf;base64,${data.data}` );
         }
         
         if ( reporteSeleccionado[0].id === 2 ) {
            arrCortes = corteSeleccionado[0].id;
            setCargargando( true );

            const data = await getConductas(arrCortes, curso);

            if (data.status === 200)
               setUrlReporte( `data:application/pdf;base64,${data.data}` );
         }

         if ( reporteSeleccionado[0].id === 3 ) {
            corteSeleccionado.map( x => arrCortes += `${x.id},`);
            
            setCargargando( true );
            const data = await getCalificacionesReprobadas(corteSeleccionado, curso);

            if (data.status === 200)
               setUrlReporte( `data:application/pdf;base64,${data.data}` );
         }

         if ( reporteSeleccionado[0].id === 4 ) {
            setCargargando( true );
            const tipo = grupoSeleccionado[0].id;
            const top = topSeleccionado[0].id;
            corteSeleccionado.map( x => arrCortes += `${x.id},`);
            
            const data = await getMejoresCalificaciones(corteSeleccionado, curso, tipo, top);

            if (data.status === 200)
               setUrlReporte( `data:application/pdf;base64,${data.data}` );
         }
      }
   }

   const CargarTerminar = () => {
      setCargargando( false );
   }
   return (
      <div style={{ maxWidth: '100%', marginLeft: 25, marginRight: 25, marginTop: 20 }}>
         <Grid container component="main" spacing={3}>
            <Grid item xs={ 12 } md={ 3 } >
               <Grid item xs={ 12 } style={{ paddingBottom: 0 }} >
                  Reporte
               </Grid>
               <Grid item xs={12} style={{ marginTop: 5, paddingBottom: 10 }}>
                  <Select options={ tiposReportes }
                     values={ [] }
                     onChange={ ( valor ) => DefinirMultiCortes(valor)  }
                     valueField='id'
                     labelField='descripcion'
                     searchBy='descripcion' />
               </Grid>
               <Grid item xs={ 12 } style={{ paddingBottom: 0 }} >
                  Corte Evaluativo
               </Grid>
               <Grid item xs={12} style={{ marginTop: 5, paddingBottom: 0 }}>
                  <Select options={ cortes }
                     multi={ multiCortes }
                     itemRenderer={ multiCortes ? CustomItemRenderer : null }
                     values={ corteSeleccionado }
                     onChange={ (valor) => setCorteSeleccionado( valor ) }
                     valueField='id'
                     labelField='descripcion'
                     searchBy='descripcion'
                      />
               </Grid>
               { reporteSeleccionado.length > 0 && reporteSeleccionado[0].id === 4 &&
               <>
               <Grid item xs={ 12 } style={{ paddingBottom: 0 }} >
                  Tipo
               </Grid>
               <Grid item xs={12} style={{ marginTop: 5, paddingBottom: 0 }}>
                  <Select options={ grupos }
                     values={ grupoSeleccionado }
                     onChange={ (valor) => setGrupoSeleccionado( valor ) }
                     valueField='id'
                     labelField='descripcion'
                     searchBy='descripcion'
                      />
               </Grid>
               </> 
               }
               { reporteSeleccionado.length > 0 && reporteSeleccionado[0].id !== 4 &&
                  <Grid item xs={12} style={{ marginTop: 10, paddingBottom: 0 }}>
                     Curso 
                  </Grid>
               }
               <Grid item xs={12} style={{ marginTop: 5, paddingBottom: 0 }}>
                  <Select options={ cursos }
                     values={ cursoSeleccionado }
                     onChange={ (valor) => setCursoSeleccionado( valor ) }
                     valueField='id'
                     labelField='descripcion'
                     searchBy='descripcion' />
               </Grid>
               { reporteSeleccionado.length > 0 && reporteSeleccionado[0].id === 4 &&
                  <>
                  <Grid item xs={ 12 } style={{ paddingBottom: 0 }} >
                     Top
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 5, paddingBottom: 0 }}>
                     <Select options={ tops }
                        values={ topSeleccionado }
                        onChange={ (valor) => setTopSeleccionado( valor ) }
                        valueField='id'
                        labelField='descripcion'
                        searchBy='descripcion'
                        />
                  </Grid>
                  </> 
                }
               <Grid item xs={12} style={{ marginTop: 20, marginRight: 0 }}>
                  <Button variant="contained" 
                           disabled={ cargando }
                           color="primary" 
                           fullWidth={true}
                           onClick={ () => VerReporte() }>
                     Generar Reporte
                  </Button>
               </Grid>
               {
                  cargando && <Grid item xs={12} style={{ marginTop: 20, marginRight: 0 }}>
                                 <LinearProgress /> Cargando...
                              </Grid>
               }
            </Grid>
            <Grid item xs={ false } md={ 9 } >
                  <VisorReportes title="Visor de reportes" 
                                 urlReporte = { urlReporte } 
                                 CargarTerminar = { CargarTerminar } />
            </Grid>
         </Grid>
      </div>
   )
}