import React from "react";
import Grid from '@material-ui/core/Grid';
import Select from "react-dropdown-select";
import { useSelector } from 'react-redux';
import { CustomItemRenderer } from '../comunes/CustomItemRenderer'

export const UIAlumnos = ( { alumnos, handleInputChange, sinActa = false } ) => { 
  const { actaActiva, alumnosFueraLista } = useSelector( state => state.calificaciones );

  const handleSelectChange = ( valor ) => {
    const e = { target: { 'name': 'alumnos', 'value': valor}};

    handleInputChange( e )
  }
  return (
    <Grid item xs={12} className="margenesGrid">
        <Grid item xs={false} md={1} className="margenesGrid">
            Alumnos
        </Grid>
        <Select options={ alumnosFueraLista } 
            multi
            itemRenderer={ CustomItemRenderer }
            valueField='id'
            labelField='descripcion'
            searchBy='descripcion'
            values={ alumnos }
            onChange={ ( valor ) => handleSelectChange( valor ) }
            placeholder='Seleccione alumno a anexar'
            disabled= { !((actaActiva.id > 0 && alumnosFueraLista.length > 0) 
                          || (sinActa && alumnosFueraLista.length > 0)) }/>
    </Grid>
  );
};