import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';

import { AddButton } from '../UI/UIAddButton';
import { UICortes } from '../UI/UICortes';
import { UIDocentes } from '../UI/UIDocentes';
import { UICursos } from '../UI/UICursos';
import { UIDiciplinas } from '../UI/UIDiciplinas';
import { UIAlumnos } from '../UI/UIAlumnos';
import { DetalleActa } from './DetalleActa';
import { DialogTitle } from '../comunes/DialogTitle';
import { useCalificaciones } from '../../hooks/useCalificaciones';
import './style.css';

export const NuevaActa = ( ) => {
    const { altoContenedorBase, UpdateOpenModal, openModalCalif, 
            actaActiva, formValues, handleInputChange, ValidarActaCal, 
            cursos, disciplinas } = useCalificaciones(); 
         
    const { corte, docente, curso, disciplina } = formValues;

    return (
        <Dialog onClose = { () => UpdateOpenModal(false) } 
                disableEscapeKeyDown
                aria-labelledby = "customized-dialog-title" 
                open = { openModalCalif }
                fullWidth = { true }
                maxWidth = 'lg'>
            <DialogTitle id = "customized-dialog-title" 
                         onClose = { () => UpdateOpenModal(false) }>
                { actaActiva.id === 0 && "Nueva" } Acta Calificaciones
            </DialogTitle>
            <DialogContent dividers style={{height: '80vh'}}>
                <Grid container 
                      component="main" 
                      spacing={3}>
                    <CssBaseline />
                    <Grid item xs={12} md={4} className="margenesGrid">
                        <UICortes actaActiva = {actaActiva.id}
                                    corte = {corte} 
                                    handleInputChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={4} className="margenesGrid">
                        <UIDocentes actaActiva = {actaActiva.id}
                                    docente = { docente} 
                                    handleInputChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={4} className="margenesGrid">
                        <UICursos cursos = {cursos}
                                  actaActiva = {actaActiva.id}
                                  curso = { curso} 
                                  handleInputChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={5} className="margenesGrid">
                        <UIDiciplinas disciplinas = {disciplinas}
                                      actaActiva = {actaActiva.id}
                                      disciplina= {disciplina} 
                                      handleInputChange ={handleInputChange}
                                      listaVisible = {true}/>
                    </Grid>
                    <Grid item xs = {6} md = {1} 
                          style = {{ marginTop: 8, marginBottom: 0, paddingTop:0, paddingBottom:0,
                                     alignSelf: 'self-end', display:'flex'}}>
                        <AddButton functionAdd = {ValidarActaCal} 
                                titleButton = "Agregar alumnos al acta"
                                titleConfirm = "¿Confirma agregar esta disciplina al Acta?"/>
                    </Grid>
                    <Grid item xs={12} md={6} className="margenesGrid">
                        <UIAlumnos handleInputChange = { handleInputChange }/>
                    </Grid>
                    <Grid item xs={12} 
                            style={{minHeight: `${altoContenedorBase - 350}px`, maxHeight: `${altoContenedorBase - 350}px`}}>
                        { <DetalleActa disciplina = { disciplina }/> }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}