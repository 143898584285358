import { types } from '../types/types';

import { getPeriodosEvaActivos } from '../../servicios/periodosEvaActivosService';
import { getDocentesActivos } from '../../servicios/calificacionesService'

export const startCargaInicial = ( docente ) => {
    return async ( dispatch ) => {
        const cortesPromise = getPeriodosEvaActivos();
        const docentesPromise = getDocentesActivos( docente );
        try {
            const [{ status, data: cortes }, { data: docentes }] = await Promise.all([ cortesPromise, docentesPromise ]);

            if ( status === 200 )
                dispatch( cargaInicial( { cortes, docentes } ));
        } catch (err) {
        }
    }
}

const cargaInicial = ( data ) => ({
    type: types.dataCargaInicial,
    payload: data 
})