import axios from "axios";
import jwtDecode from "jwt-decode";
import { Logout } from "../state/actions/auth";
import { store } from "../state/store/store";

const tokenKey = "access_token";
const apiURL = process.env.REACT_APP_API_URL;

const axiosApiInstance = axios.create({ baseURL: apiURL,
                                        timeout: 30000, });

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use( async config => {
    const jwt = localStorage.getItem(tokenKey);

    config.headers = { 'Authorization': `Bearer ${jwt}` }
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    
    if (error.response.status === 403 && !originalRequest._retry) {
        return axiosApiInstance(originalRequest);
    }

    if (401 === error.response.status) {
       store.dispatch( Logout())
    }
    return Promise.reject(error);
});

///////////////////////////////////////////////
export function getJwt() {
    return localStorage.getItem(tokenKey);
}
export function getDataJwt() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);
    } catch (error) {
        return null;
    }
}
export default {
    get: axiosApiInstance.get,
    post: axiosApiInstance.post,
    delete: axiosApiInstance.delete,
};