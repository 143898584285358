import React from 'react';
import { Provider } from 'react-redux';

import { store } from './state/store/store';
import { AppRouter } from './router/AppRouter';

export const App= () => {
  return (
    <Provider store= { store }>
      <AppRouter/>
    </Provider>
  );
}

