import http from "./httpService";

export function getCortes(docente) {
    return http.get(`Informes/Cortes`);
}

export function getCursos(docente, tipo = 1) {
    const url = `Informes/Cursos/${docente}/${tipo}`
  
    return http.get(url);
}

export function getCalificaciones( cortes, curso ) {
    const data = { "cortes":cortes, "curso":curso }

    if (cortes.length > 0 && curso > 0)
        return http.post('Informes/CalificacionesCurso', data);
    
    return null;
}

export function getCalificacionesReprobadas( cortes, curso ) {
    const data = { "cortes":cortes, "curso":curso }

    if (cortes.length > 0 && curso > 0)
        return http.post('Informes/CalificacionesReprobadas', data);
    
    return null;
}

export function getMejoresCalificaciones( cortes, curso, tipo, top ) {
    const data = { "cortes":cortes, "curso":curso, "tipo": tipo, "top": top }

    if (cortes.length > 0 && curso > 0 && tipo > 0 & top > 0)
        return http.post('Informes/MejoresCalificaciones', data);
    
    return null;
}

export function getConductas( corte, curso ) {
    if (corte > 0 && curso > 0)
        return http.get(`/Informes/CoductasCurso/${corte}/${curso}`);

    return null;
}