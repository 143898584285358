import React, { useState} from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from '../UI/ConfirmDialog'

export const DeleteButton = ({functionDelete, data, title, titleComfirm, icon = 'Delete'}) => {
    const [confirmOpen, setConfirmOpen] = useState( false );

    const ConfirmDelete = () => setConfirmOpen( true );

    return (
        <>
            <Tooltip title={title}>
                <IconButton edge="start" 
                            color="default" 
                            aria-label="menu" 
                            style={{ padding: 3, marginLeft: 5 }} 
                            onClick={ ConfirmDelete }>
                    { icon === 'Delete' 
                        ? <CancelIcon/>
                        : <DeleteIcon/>
                    }                    
                </IconButton>
            </Tooltip>
            <ConfirmDialog open = {confirmOpen}
                           title = { titleComfirm } 
                           setOpen = {setConfirmOpen} 
                           onConfirm = {() => functionDelete(data)} />
        </>
    )
}
