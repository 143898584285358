import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { startAddPlanificacionSemanal,
         startAddDetallePlanificacionSemanal,
         startAddFechaPlanificacionSemanal,
         startDelFechaPlanificacionSemanal,
         AddNewContent,
         LoadContent,
         CargarTemasUnidad, 
         startDelContenido} from '../state/actions/actionsPlanificacion';

const StateInitial = () => {
    const { actaPlaniSemanal } = useSelector( state => state.planificacion );

    const stateInitial = {
        planificacionSemanalId :  actaPlaniSemanal.id, 
        id : 0, 
        unidad : [],
        tema : [], 
        contenido : '',
        indicadorLogro : '', 
        estrategiaAplicar: '',}
        
    return stateInitial;
}

export const usePlanificacionDetalle = ( initialState = StateInitial()) => {
    const { actaPlaniSemanal, unidades, temasView, cursos, contenidoSemanal, contenidoActivo } = useSelector( state => state.planificacion );

    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initialState);
    const validationSchema = Yup.object({
        estrategiaAplicar: Yup.string().required("Ingrese la estrategia a aplicar"),
        indicadorLogro: Yup.string().required("Ingrese el indicador logro"),
        contenido: Yup.string().required("Ingrese el contenido"),
        tema: Yup.array().min(1, "Seleccione un tema"),
        unidad: Yup.array().min(1, "Seleccione una unidad")});

        const handleInputChange = ( { target } ) => {
        setFormValues({ ...formValues,
                        [ target.name ] : target.value });
    }

    const handleSelectChange = ( valor, name ) => {
        const e = { target: { 'name': name, 'value': valor }};
        handleInputChange( e )
    }
    
    useEffect(() => {
        const actualizarPlanificacionId = () => {
            const e = { target: { 'name': 'planificacionSemanalId', 'value': actaPlaniSemanal.id}};
            
            handleInputChange( e );
        }
        actualizarPlanificacionId();
    }, [ actaPlaniSemanal ]);

    useEffect(() => {
        const LoadContent = () => {
            setFormValues({...contenidoActivo});
        }
        LoadContent();
    }, [ contenidoActivo ]);

    useEffect(() => {
        const cargarTemas = () => {
              if ( formValues.unidad.length > 0 )                
                dispatch( CargarTemasUnidad( formValues.unidad[0].id ) );
        }
        cargarTemas();
    }, [ formValues.unidad ]);
    //////////////////////////////////////////////
    const ValidarDetalle = ( data ) => {
        try {
            return validationSchema.validateSync(data);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }

    const AddContenido = async ( data) => {
        const datos = { ...formValues,
                        planificacionSemanalId: actaPlaniSemanal.id,
                        id: contenidoActivo.id}
            
        if (ValidarDetalle( datos ))
            if (actaPlaniSemanal.id === 0)
                await dispatch( startAddPlanificacionSemanal( data, datos ) );
            else
                await dispatch( startAddDetallePlanificacionSemanal( datos, (contenidoActivo.id > 0) ) );
    }

    const DelContenido = () => {
        if ( formValues.id > 0 )
            dispatch( startDelContenido( formValues.id ));
    }

    const AddNewContenido = () => {
        dispatch( AddNewContent() )
    }
    const LoadContenido = (id) => {
        dispatch( LoadContent( id ) );
    }

    const NuevaFecha= async (data) => await dispatch( startAddFechaPlanificacionSemanal( data ) );
    
    const QuitarFecha= async (data) => 
        await dispatch( startDelFechaPlanificacionSemanal( data ) );
    
    return { LoadContenido, AddNewContenido, AddContenido,
             formValues, handleInputChange, handleSelectChange, actaPlaniSemanal, unidades, temasView, cursos, contenidoSemanal, NuevaFecha, QuitarFecha, contenidoActivo, DelContenido};
}