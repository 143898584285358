import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from "react-dropdown-select";
import { useSelector } from 'react-redux';

export const UIDocentes = ( { actaActiva = 0, docente, handleInputChange, esEditable =  false } ) => { 
  const { docentes } = useSelector( state => state.general );

  const docenteActivo = docentes.filter(x => x.id === docente);
  const nombreDocente = docenteActivo.length > 0 ? docenteActivo[0].descripcion : "";

  const handleSelectChange = ( valor ) => {
    if (valor.length > 0) {
      const e = { target: { 'name': 'docente', 'value': valor[0].id}};
      handleInputChange( e )
    }
  }
  return (
    <Grid item xs={12}>
      <Grid item xs={12} >
        Docente
      </Grid>
      {actaActiva > 0 ? (
        <TextField
          size="small"
          fullWidth
          multiline
          minRows={1}
          defaultValue={ nombreDocente }
          disabled={true}/>
      ) : (
        <Select
          options={docentes}
          onChange={ valor => handleSelectChange(valor) }
          values={docenteActivo}
          valueField="id"
          labelField="descripcion"
          searchBy="descripcion"
          disabled={!(docentes.length > 0)}/>
      )}
    </Grid>
  );
};