import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { startUnidadesDidacticas, } from '../state/actions/actionsPlanificacion';
import { startLoadPlaniDiaria,
         OpenModalPlaniDiaria,
         startUpdPlanificacionDiaria,
         CerrarActaPlanificacionDiaria } from '../state/actions/actionsPlanificacionDiaria';

const StateInitial = () => {
    const stateInitial = { planificacionSemanalDetalleId: [],
                           id: 0,
                           familiaValor : "",
                           usuario: 0,}

    return stateInitial;
}

//familiaValor: Yup.string().required("Ingrese la familia de valores"),
const validationSchema = Yup.object({
                    planificacionSemanalDetalleId: Yup.number().min(1, "Seleccione el Contenido"),
                });

export const usePlanificacion = ( initialState = StateInitial()) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initialState);
    const [cursosAct, setCursosAct] = useState([]);
    const [data, setData] = useState({});

    const { docente, altoContenedor } = useSelector( state => state.auth );
    const { contenidos, cursos, actaPlaniDiaria, openModalPlaniDiaria, actividades, revisionesDiaria } = useSelector( state => state.planificacionD );
    const { vstUnidadDidactica } = useSelector( state => state.planificacion );
    const altoContenedorBase = altoContenedor - 150;

    let contenidosAct = []

    contenidos.map(item => contenidosAct.push({'id': item.id, 'descripcion': item.contenido}) );

    const handleInputChange = ( { target } ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : target.value
        });
    }
    const handleSelectChange = ( valor, name ) => {
        const e = { target: { 'name': name, 'value': valor }};
        handleInputChange( e )

        setCursosAct(cursos.filter( item => item.idDetalle == valor[0].id ))
        setData(contenidos.filter( item => item.id == valor[0].id )[0])
    }
    
    useEffect(() => {
        const load = () => {
            if (actaPlaniDiaria.id == 0)
                dispatch( startLoadPlaniDiaria(vstUnidadDidactica.id));
            else {
                const valor = [{'id': actaPlaniDiaria.planificacionSemanalDetalleId,
                                'descripcion': contenidos[0].contenido}];

                handleSelectChange( valor, 'planificacionSemanalDetalleId' )        
            }
        }
        load()
        return () => {
            dispatch( CerrarActaPlanificacionDiaria() );
            dispatch( startUnidadesDidacticas(docente) );
        } 
    }, []);

    const ValidarActa = () => {
        try {
            const data = {...formValues,
                          planificacionSemanalDetalleId: formValues.planificacionSemanalDetalleId[0].id}
            
            return validationSchema.validateSync(data);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }    
    
    const UpdateOpenModalPlaniDiaria = ( valor ) => {
        dispatch( OpenModalPlaniDiaria( valor ))
        dispatch( CerrarActaPlanificacionDiaria)
    };

    const UpdActaPlanDiario = async()  => {
        if (ValidarActa()){
            const data = {...formValues,
                id : actaPlaniDiaria.id,
                planificacionSemanalDetalleId: formValues.planificacionSemanalDetalleId[0].id };

            await dispatch( startUpdPlanificacionDiaria( data ) );
        }
    }

    const RevisinesPendientes = () => revisionesDiaria.length == 0 ? 0 : revisionesDiaria.filter(x => x.aplicado == false).length; 

    return { altoContenedor, altoContenedorBase, 
             UpdateOpenModalPlaniDiaria, openModalPlaniDiaria, 
             handleInputChange, handleSelectChange, UpdActaPlanDiario,
             formValues, actaPlaniDiaria, contenidosAct, cursosAct, data, ValidarActa, actividades, RevisinesPendientes };
}