import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ConfirmDialog from '../UI/ConfirmDialog'
import { Alert } from '../../helpers/functions';

export const CatalogoBase = ({ Nuevo, Valid, Quitar, data, title, titleValid }) => {

	const [verDetalle, setVerDetalle] = useState(true);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [id, setId] = useState(0);
	const [valorGuardar, setValorGuardar] = useState("");

	const Validar = (  ) => {
		if ( Valid() )
			if ( valorGuardar === "" )
				Alert( 'error', `Ingrese el ${titleValid}`);
			else 
				setConfirmOpen(true)
	}

	const DeleteRow = (id) => {
		setId(id);
		setConfirmDelete(true);

	}
	return (
		<Grid container className="margenesGrid" >
			<Grid item xs={11} className="margenesGrid subtitulo" >
            	{title} 
				<span style={{fontSize:'0.8em', color: 'blue', marginLeft:10}}>
					({ data.length } Elementos)
				</span>
			</Grid>
			<Grid item xs={1}  align="center" >
				<Tooltip title={verDetalle ? "Contraer" : "Expandir"}>
					<IconButton	edge="start"
								color="inherit"
								aria-label="menu"
								style={{ padding: 4 }}
								onClick={() => setVerDetalle( !verDetalle )}>
						{ verDetalle ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
					</IconButton>
				</Tooltip>
			</Grid>
						
			<Grid container
				component="main" style={{marginBottom:8, marginTop: 0}}>
				<Grid	item
						xs={11} >
					<TextField	size="small"
								fullWidth
								multiline
								minRows={1}
								maxRows={5}
								value={valorGuardar}
								onChange={ e => setValorGuardar(e.target.value)}
								label="Nuevo Registro"/>
				</Grid>
				<Grid	item
						xs={1}
						align="center">
					<Tooltip title="Agregar">
						<IconButton	edge="start"
									color="inherit"
									aria-label="menu"
									style={{ padding: 4 }}
									onClick={ Validar }>
							<AddCircleIcon/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			{ verDetalle &&
			<Grid container
				style={{ maxHeight: 115, overflowX: 'hidden', overflowY: "auto"}}>

				{
                	data.map( item =>
						<Grid key= {item.id.toString()}	container component="main" style={{marginBottom:8}} >
							<Grid	item
									xs={11}
									style={{ margin:0 , paddingLeft: 0 }}>
								<TextField	size="small"
											fullWidth
											multiline
											minRows={1}
											maxRows={5}
											defaultValue={item.descripcion}
											disabled={true}/>
							</Grid>
							<Grid	item
									xs={1}
									align="center"
									>
								<Tooltip title="Quitar">
									<IconButton	edge="start"
												color="default"
												aria-label="menu"
												style={{ padding: 4 }}
												onClick={() => DeleteRow(item.id)}>
										<RemoveCircleIcon/>
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid> 
					)
				}
			</Grid>
			}
			<ConfirmDialog 
                title = {`¿Confirma agregar este ${titleValid} ?`} 
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { () => Nuevo( valorGuardar, setValorGuardar ) } />

			<ConfirmDialog 
                title = {`¿Confirma quitar este ${titleValid} ?`} 
                open = { confirmDelete } 
                setOpen = { setConfirmDelete } 
                onConfirm = { () => Quitar( id ) } />
		</Grid>	
	);
};