import React, { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CssBaseline from '@material-ui/core/CssBaseline';
import Select from "react-dropdown-select";
import Tooltip from "@material-ui/core/Tooltip";

import { DialogTitle } from '../../comunes/DialogTitle';
import { AddDetallePlaniDiaria } from './AddDetallePlaniDiaria';
import { RevisionesUnidadDidactica } from '../RevisionesUnidadDidactica';

import { FechaCursosDetalle } from '../Semanal/FechaCursosDetalle'
import { usePlanificacion } from '../../../hooks/usePlanificacionDiaria';
import ConfirmDialog from '../../UI/ConfirmDialog'

import '../style.css';

export const NuevaPlanificacionDiaria = () => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [tapSeleccionada, setTapSeleccionada] = useState('agregar');
    const { altoContenedorBase, UpdateOpenModalPlaniDiaria, openModalPlaniDiaria,
            actaPlaniDiaria, formValues, handleSelectChange, contenidosAct, cursosAct, data, UpdActaPlanDiario, ValidarActa, RevisinesPendientes } = usePlanificacion(); 
    
    const { planificacionSemanalDetalleId } = formValues;
    
    return (
        <Dialog onClose = { () => UpdateOpenModalPlaniDiaria(false) } 
                disableEscapeKeyDown
                aria-labelledby = "customized-dialog-title" 
                open = { openModalPlaniDiaria }
                fullWidth
                maxWidth = 'lg'>
            <DialogTitle id = "customized-dialog-title" 
                         onClose = { () => UpdateOpenModalPlaniDiaria(false) }>
                Planificacion Diaria
            </DialogTitle>
            <DialogContent dividers
                           style={{ minHeight: `${altoContenedorBase}px`, 
                                    maxHeight: `${altoContenedorBase}px`}}>
                <Grid container component="main" spacing={3}>
                    <CssBaseline />
                    <Grid item xs={12} md={5}>
                        <Tooltip title="Seleccione el Contenido a Planificar">
    						<Select
                                options={contenidosAct}
                                onChange={ valor => handleSelectChange(valor, 'planificacionSemanalDetalleId') }
                                values={planificacionSemanalDetalleId}
                                valueField="id"
                                labelField="descripcion"
                                searchBy="descripcion"
                                disabled= {(actaPlaniDiaria.id > 0)}/>
                        </Tooltip>
					</Grid>

                    <Grid item xs={12} md={7} className="margenesGrid">
                        <FechaCursosDetalle QuitarFecha={()=>0}
                                            CursosAct= { cursosAct }
                                            esEditable={false}/>
                    </Grid>
                    <Grid item xs={12} md={6} className="margenesGrid">
                        Competencia de Grado
                        <TextField size='small'
                                fullWidth
                                multiline
                                minRows={1}
                                value={data.competenciaGrado}
                                disabled={true}/>
                    </Grid>
                    <Grid item xs={12} md={6} className="margenesGrid">
                        Eje Tranversal
                        <TextField size='small'
                                fullWidth
                                multiline
                                minRows={1}
                                value={data.ejeTranversal}
                                disabled={true}/>
                    </Grid>
                    <Grid item xs={12} md={6} className="margenesGrid">
                        Indicador Logro
                        <TextField size='small'
                                fullWidth
                                multiline
                                minRows={1}
                                value={data.indicadorLogro}
                                disabled={true}/>
                    </Grid>
                    <Grid item xs={12} md={6} className="margenesGrid">
                        Familia Valor
                        <TextField size='small'
                                   fullWidth
                                   multiline
                                   minRows={1}
                                   value={data.principiosValor}
                                   disabled={true}/>
                    </Grid>
                    { /*actaPlaniDiaria.id > 0 && 
                        <Grid item xs={12} md={1} className="margenesGrid">
                            <Tooltip title="Guardar cambios">
                                <Button variant="contained"
                                        fullWidth
                                        color="primary"
                                        onClick={ () => setConfirmOpen(true)} >
                                        <SaveIcon/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    */}
                    <Grid item xs={12} className="margenesGrid">
                        <Tabs value={tapSeleccionada} onChange={ (e, nv) => setTapSeleccionada(nv) }
                              style={{backgroundColor:'#7EC6FB'}} >
                            <Tab value="agregar"
                                 label="Actividades"
                                 wrapped/>
                            {actaPlaniDiaria.id > 0 && <Tab value="revisiones" 
                                 label={<Badge badgeContent={RevisinesPendientes()}
                                               color="secondary">
                                            Revisiones
                                        </Badge> } />
                            }
                        </Tabs>
                        { tapSeleccionada === 'agregar' 
                            ? <AddDetallePlaniDiaria data={formValues} valid={ValidarActa}/>
                            : <RevisionesUnidadDidactica tipo='PD'/>
                        }
                    </Grid>
                </Grid>
                <ConfirmDialog 
                    title = {`¿Confirma Actualizar esta Planificacion Diaria?`} 
                    open = { confirmOpen } 
                    setOpen = { setConfirmOpen } 
                    onConfirm = { () => UpdActaPlanDiario() } />
            </DialogContent>
        </Dialog>
    );
}