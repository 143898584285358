import React, { useState} from 'react'
import Chip from '@material-ui/core/Chip';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import PostAddIcon  from '@material-ui/icons/PostAdd';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmDialog from '../UI/ConfirmDialog';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

export const UIBotonera = ({lista = [], functionLoad, functionAddNew, functionAdd, functionDel, titleButton = 'Unidad', opcion = 1, unidad = 0}) => {
    const [confirmOpen, setConfirmOpen] = useState( false );
    const [confirmDel, setConfirmDel] = useState( false );

    if (opcion === 2)
        lista = lista.filter(item => item.planificacionMensualDetalleId === unidad);
        
    return (
        <>
                {lista != null && lista.length > 0 &&
                <Tooltip title={ `Agregar ${titleButton}` }>
                    <IconButton variant="contained" 
                                color="primary" 
                                style ={{padding:'3px'}}
                                fullWidth
                                onClick={() => functionAddNew()}>
                        <PostAddIcon/>
                    </IconButton>
                </Tooltip>}
            {lista != null && lista.map(item =>
                <IconButton variant="contained" 
                        key = {Math.random()}
                        color="primary" 
                        style ={{padding:'3px'}}
                        fullWidth
                        onClick={() => functionLoad( item.id)}>
                        <Chip label={(opcion === 1 ? item.unidad : opcion === 2 ? item.tema : item.contenido ? item.contenido : item.titulo)}/>
                </IconButton>
            )}
            <Tooltip title={ `Guardar ${titleButton}` }>
                <IconButton variant="contained" 
                            color="primary" 
                            style ={{padding:'3px'}}
                            fullWidth
                            onClick={() => setConfirmOpen(true)}>
                        <SaveIcon/>
                </IconButton>
            </Tooltip>
            {lista != null && lista.length > 0 &&
                <Tooltip title={ `Quitar ${titleButton}` }>
                    <IconButton variant="contained" 
                                color="primary" 
                                fullWidth
                                onClick={() => setConfirmDel(true)}>
                        <RemoveCircleIcon/>
                    </IconButton>
                </Tooltip>}
            <ConfirmDialog 
                title = {`¿Confirma guardar ${titleButton}?`} 
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { () => functionAdd() } />
            <ConfirmDialog 
                title = {`¿Confirma quitar  ${titleButton}?`} 
                open = { confirmDel } 
                setOpen = { setConfirmDel } 
                onConfirm = { () => functionDel() } />
        </>
    )
}