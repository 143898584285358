import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-dropdown-select";
import { useSelector } from 'react-redux';

export const UIFamiliaValores = ({ familiaValores, handleInputChange }) => {
  const { familiaValores : data } = useSelector( state => state.planificacion );
  let valor = "";

  if (data != null)
    data.map(item => valor += item.descripcion + ', ');
  
  const handleSelectChange = ( valor ) => {
    if ( valor.length > 0) {
      const e = { target: { 'name': 'familiaValores', 'value': valor}};
      handleInputChange( e );
    }
  }
  return (
    <Grid item xs={12} >
      <Grid item xs={12} >
        Familia Valores
      </Grid>
      <Select options={data}
                  values={familiaValores}
                  valueField="id"
                  multi
                  labelField="descripcion"
                  searchBy="descripcion"
                  onChange={ valor => handleSelectChange(valor) }
                  disabled={!(data != null && data.length > 0)}/>
    </Grid>
  );
};