export const types = {
    autAutenticado: "[aut] Usuario Autenticado",
    autLogout:"[aut] Logout",
    autAutenticacionError:"[aut] Autenticacion Error",

    uiOpenModalCalif : '[ui] Open Modal Calificaciones',
    uiOpenModalObser : '[ui] Open Modal Obserservaciones',
    uiOpenModalPlani : '[ui] Open Modal Planifacion',
    uiOpenModalPlaniSemanal : '[ui] Open Modal Plani Semanal',
    uiOpenModalPlaniDiaria: "[ui] Open Modal PlaniDiaria",

    dataCargarCalificaciones : "[data] Cargar Calificaciones",
    dataCargaInicial : "[data] Carga Inicial",
    dataCargaCursoGuiado : "[data] Carga Curso Guiado",
    dataCargaDetalleObservaciones : "[data] Carga Detalle Observaciones",
    dataUpdateObservacion : "[data] Update Observacion",
    dataDeleteObservacion: "[data] Delete Observacion",
    dataCerrarDetalleObservaciones: "[data] Cerrar Detalle Observaciones",
    dataCerrarCalificaciones: "[data] Cerrar Calificaciones",
    dataAnularActaCalificacion: "[data] Anular Acta Calificacion",
    dataDisciplinasCursoDocente: "[data] Disciplinas Curso Docente",
    dataCursosDocente: "[data] Cursos Docente",
    dataAlumnosActa: "[data] Alumnos Acta",
    dataUpdateCalificacion: "[data] Update Calificacion",
    dataDeleteCalificacion: "[data] Delete Calificacion",
    dataAddActaCalificaciones: "[data] Add Acta Calificaciones",
    dataCerrarActaCalificacion: "[data] Cerrar Acta Calificacion",

    dataPlanificacionMensualActiva: "[data] Planificacion Mensual Activa",
    dataGradosDocente: "[data] Grados Docentes",
    dataDisciplinasGradoDocente:"[data] Disciplinas Grado Docente",
    dataAddPlanificacionMensual: "[data] Add Planificacion Mensual",
    dataAddIndicadorLogro: "[data] Add Indicador Logro",
    dataDeleteIndicadorLogro: "[data] Delete Indicador Logro",
    dataAddDetallePlanificacionMensual: "[data] Add Detalle Planificacion Mensual",
    dataDeleteDetallePlanificacionMensual: "[data] Delete Detalle Planificacion Mensual",
    dataCargarUnidadesDidacticas: "[data] Cargar Unidades Didacticas",
    dataCerrarPlanificacion: "[data] Cerrar Planificacion",
    dataCargarUnidadDidactica: "[data] Cargar Unidad Didactica",
    dataCerrarActaPlanificacion: "[data] Cerrar Acta Planificacion",
    dataDeletePlanificacionMensual: "[data] Delete Planificacion Mensual",
    dataLoadVstUnidadDidactica: "[data] Load Vst Unidad Didactica",
    dataCargarUnidadesCursos: "[data] Cargar Unidades Cursos",
    dataPlanificacionSemanalActiva: "[data] Planificacion Semanal Activa",
    dataDeleteDetallePlanificacionSemanal: "[data] Delete Detalle Planificacion Semanal",
    dataCargarPlanificacionSemanal: "[data] Cargar Planificacion Semanal",
    dataCerrarActaPlanificacionSemanal:"[data] Cerrar Acta Planificacion Semanal",
    dataAddRevisionPlanificacionMensual: "[data] Add Revision Planificacion Mensual",
    dataDeleteRevisionPlanificacionMensual: "[data] Delete Revision Planificacion Mensual",
    dataUpdateRevisionPlanificacionMensual: "[data] Update Revision Planificacion Mensual",
    dataCargarPlanificacionDiaria: "[data] Cargar Planificacion Diaria",
    dataCargarFechas: "[data] Cargar Fechas",
    dataCerrarActaPlanificacionDiaria: "[data] Cerrar Acta Planificacion Diaria",
    dataAddDetallePlanificacionDiaria: "[data] Add Detalle Planificacion Diaria",
    dataPlanificacionDiariaActiva: "dataPlanificacionDiariaActiva",
    dataDeleteDetallePlanificacionDiaria:"dataDeleteDetallePlanificacionDiaria",
    dataFamiliaValores:"dataFamiliaValores",
    dataDeleteContenidoUnidad:"dataDeleteContenidoUnidad",
    AddContenidoUnidad:"dataAddContanidoUnidad",
    dataLoadDetalle: 'dataLoadDetalle',
    dataCargarContenidosUnidad:'dataCargarContenidosUnidad',
    dataDeletePlanificacionSemanal: 'dataDeletePlanificacionSemanal',
    dataAddRevisionPlanificacionSemanal: 'dataAddRevisionPlanificacionSemanal',
    dataDeleteRevisionPlanificacionSemanal: 'dataDeleteRevisionPlanificacionSemanal',
    dataUpdateRevisionPlanificacionSemanal: 'dataUpdateRevisionPlanificacionSemanal',
    dataPrincipiosValores: "dataPrincipiosValores",
    dataLoadUnit:"dataLoadUnit",
    dataAddNewUnit:"dataAddNewUnit",
    dataAddUnidad: "dataAddUnidad",
    dataUpdUnidad: "dataUpdUnidad",
    dataLoadTopic: "dataLoadTopic",
    dataAddNewTopic:"dataAddNewTopic",
    dataAddTopic: "dataAddTopic",
    dataUpdTopic: "dataUpdTopic",
    dataLoadContent:"dataLoadContent",
    dataAddNewContent:"dataAddNewContent",
    dataAddContent: "dataAddContent",
    dataUpdContent: "dataUpdContent",
    dataUpdPlanificacionSemanal:"dataUpdPlanificacionSemanal",
    actaActivaInicial : "[data] actaActivaInicial",
    actaActivaActualizar : "[data] actaActivaActualizar",
    dataAddActaListaPlanificacionMensual: "dataAddActaListaPlanificacionMensual",
    dataUpdActaListaPlanificacionMensual: "dataUpdActaListaPlanificacionMensual",
    dataCargarTemasUnidad:"dataCargarTemasUnidad",
    dataActualizarEI: "dataActualizarEI",
    dataAddDate: "dataAddDate",
    dataDelDate: "dataDelDate",
    dataCargarContenidos: "dataCargarContenidos",
    dataAddNewActividad: "dataAddNewActividad",
    dataLoadActividad: "dataLoadActividad",
    dataUptDetallePlanificacionDiaria: "dataUptDetallePlanificacionDiaria",
    dataAddRevisionPlanificacionDiaria: "dataAddRevisionPlanificacionDiaria",
    dataDelRevisionPlanificacionDiaria: "dataDelRevisionPlanificacionDiaria",
    dataUpdRevisionPlanificacionDiaria: "dataUpdRevisionPlanificacionDiaria",
    dataDelUnidad: "dataDelUnidad",
    dataDelTema: "dataDelTema",
    dataDelContenido: "dataDelContenido",
    dataCerrarActaPL: "dataCerrarActaPL",
}