import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import moment from 'moment';

import ConfirmDialog from '../../UI/ConfirmDialog'

export const FechaCursosDetalle = ({ QuitarFecha, CursosAct=[], verDetalle= true, esEditable = true, contenidoId=0 }) => {
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [id, setId] = useState(0);

	const DeleteRow = (id) => {
		setId(id);
		setConfirmDelete(true);
	}
	return (
		<Grid container className="margenesGrid" >
			{verDetalle &&
			<Grid container
				style={{ maxHeight: 115, overflowX: 'hidden', overflowY: "auto"}}>

				{ CursosAct &&
                	CursosAct.map( item =>
						<Grid key= {item.id.toString()}	container component="main" style={{marginBottom:8}} >
							<Grid	item
									xs={esEditable ? 11 : 12}
									style={{ margin:0 , paddingLeft: 0 }}>
								<TextField	size="small"
											multiline
											minRows={1}
											fullWidth
											defaultValue={moment(item.fecha).format('DD/MM/YYYY') + ", " +item.cursos }
											disabled={true}/>
							</Grid>
							{esEditable &&
							<Grid	item
									xs={1}
									align="center">
								<Tooltip title="Quitar">
									<IconButton	edge="start"
												color="default"
												aria-label="menu"
												style={{ padding: 4 }}
												onClick={() => DeleteRow(item.id)}
												>
										<RemoveCircleIcon/>
									</IconButton>
								</Tooltip>
							</Grid>
							}
						</Grid> 
					)
				}
			</Grid>
			}

			<ConfirmDialog 
                title = {`¿Confirma quitar esta fecha?`} 
                open = { confirmDelete } 
                setOpen = { setConfirmDelete } 
                onConfirm = { () => QuitarFecha( {"detalle": contenidoId, "id": id }) } />
		</Grid>	
	);
};