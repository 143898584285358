import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from "react-dropdown-select";
import moment from 'moment';

import ConfirmDialog from '../../UI/ConfirmDialog'
import { FechaCursosDetalle } from './FechaCursosDetalle'
import { Alert } from '../../../helpers/functions';

export const FechaCursos = ({ NuevaFecha, QuitarFecha }) => {
	const { cursos, contenidoActivo } = useSelector( state => state.planificacion );

	const [verDetalle, setVerDetalle] = useState(true);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [contenidoId, setContenidoId] = useState(0);
	const [fecha, setFecha] = useState(moment(new Date()).format('YYYY-MM-DD'));
	const [curso, setCurso] = useState([]);
	const [fecCursos, setFecCursos] = useState([]);

	useEffect(() => {
		const Load = () => {
			if (contenidoActivo) {
				setContenidoId(contenidoActivo.id);
				setFecCursos(contenidoActivo.fechasCursos.filter( item => item.idDetalle == contenidoActivo.id ))
			} 
		}
        Load();
    }, [ contenidoActivo ]);
	
	const Validar = (  ) => {
		if ( curso.length === 0 )
			Alert( 'error', `Seleccione la fecha y los cursos a aplicar`);
		else 
			setConfirmOpen(true)
	}

	const RegistrarFecha = () =>{
		const data = {idDetalle : contenidoId,
					  fecha,
					  cursos : curso}
					  
		NuevaFecha(data);
		setCurso([])
	}

	return (
		<Grid container className="margenesGrid" >
			<Grid item xs={11} className="margenesGrid subtitulo" >
				<span style={{fontSize:'0.8em', color: 'blue', marginLeft:10}}>
					({ fecCursos && fecCursos.length } Elementos)
				</span>
			</Grid>
			<Grid item xs={1}  align="center" >
				<Tooltip title={verDetalle ? "Contraer" : "Expandir"}>
					<IconButton	edge="start"
								color="inherit"
								aria-label="menu"
								style={{ padding: 4 }}
								onClick={() => setVerDetalle( !verDetalle )}>
						{ verDetalle ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
					</IconButton>
				</Tooltip>
			</Grid>
			
			<Grid container
				  component="main" 
				  style={{marginBottom:8, marginTop: 0}}>
				<Grid item xs={6} md={3} style={{paddingRight:15}}>
						<TextField size='small'
									fullWidth
									label="Fecha"
									type='date'
									value={moment(fecha).format('YYYY-MM-DD')}
									name="fecha"
									onChange={ e => setFecha( e.target.value )}
									disabled={(contenidoId === 0)}/>
				</Grid>
				<Grid item xs={12} md={8}>
					<Select options={cursos}
							multi
							onChange={ valor => setCurso(valor) }
							values={curso}
							valueField="id"
							labelField="descripcion"
							searchBy="descripcion"
							disabled={(contenidoId === 0)}/>
				</Grid>
				<Grid item
						xs={1}
						align="center">
					<Tooltip title="Agregar">
						<IconButton	edge="start"
									color="inherit"
									aria-label="menu"
									style={{ padding: 4 }}
									onClick={ Validar }
									disabled={(contenidoId === 0)}>
							<AddCircleIcon/>
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
			<FechaCursosDetalle QuitarFecha = { QuitarFecha }
								CursosAct = { fecCursos }
								verDetalle = { verDetalle }
								contenidoId = {contenidoId}/>
			<ConfirmDialog 
                title = {`¿Confirma agregar esta fecha?`} 
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { () => RegistrarFecha() } />
		</Grid>	
	);
};