import http from "./httpService";
import { getDataJwt} from "./httpService";

export function getDocentesCursosGuias( docente ) {
    let apiEndPointLC = "/Docentes/guias";

    if ( docente > 0 )
        apiEndPointLC = `${apiEndPointLC}/${docente}`;

    return http.get( apiEndPointLC );
}
export function getDetalleObservaciones( acta) {
  const apiEndPointLC = `/Observaciones/detalle/${acta.corte}/${acta.docente}/${acta.curso}`;

  return http.get( apiEndPointLC );
}
export function putDetalleObservacion(id, observacion) {
  const obs = { "id": id,
                 "observacion": observacion }

  let apiEndPoint = `/Observaciones/update`;
  
  return http.post( apiEndPoint, obs ).then( resp => resp).catch(err => err);
}
export function deleteObservaciones( id ) {
  let apiEndPoint = `/Observaciones/delete/${id}`;
  
  return http.get( apiEndPoint );
}
export function postAddObservaciones(data) {
  let { usuario } = getDataJwt();

  data.usuario = parseInt(usuario);
  let apiEndPoint = `/Observaciones`;
 
  return http.post( apiEndPoint, data );
  //.then( resp => resp).catch(err => err);
}