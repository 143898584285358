import { useDispatch, useSelector } from 'react-redux';

import { startAddRevisionUnidadDidactica,
         startDeleteRevisionUnidadDidactica,
         startApplyRevisionUnidadDidactica,
         startAddRevisionPlanificacionSemanal,
         startApplyRevisionPlanificacionSemanal,
         startDeleteRevisionPlanificacionSemanal,
          } from '../state/actions/actionsPlanificacion';

import { startAddRevision,
         startDelRevision,
         startAppRevision, } from '../state/actions/actionsPlanificacionDiaria';

export const usePlanificacionRevision = () => {
    const dispatch = useDispatch();
    const { docente } = useSelector( state => state.auth );
    const { actaActiva, actaPlaniSemanal, revisionesSemanal } = useSelector( state => state.planificacion );
    const { actaPlaniDiaria, revisionesDiaria } = useSelector( state => state.planificacionD );
    const {revisiones} = actaActiva    

    const Listar = (tipo) => {
        if (tipo === 'UD')
            return revisiones; 
        
        if (tipo === 'PS')
            return revisionesSemanal; 
        
        if (tipo === 'PD')
            return revisionesDiaria;
    }

    const AddRevision = async ( tipo, revision, setRevision) => {
        const data = { id : 0,
                       revision : revision,
                       aplicado : false};

        if (tipo === 'UD') {
            data.planificacionMensualId = actaActiva.id;
            
            dispatch( startAddRevisionUnidadDidactica( data )).then( setRevision('')); 
        }
        if (tipo === 'PS') {
            data.planificacionSemanalId = actaPlaniSemanal.id;

            dispatch( startAddRevisionPlanificacionSemanal( data )).then( setRevision(''));
        }
        
        if (tipo === 'PD') {
            data.planificacionDiariaId = actaPlaniDiaria.id;

            dispatch( startAddRevision( data )).then( setRevision(''));
        }

    }

    const DeleteRevision = async (tipo, id) => {
        if (tipo === 'UD')
            dispatch( startDeleteRevisionUnidadDidactica( id )); 
        
        if (tipo === 'PS')
            dispatch( startDeleteRevisionPlanificacionSemanal( id )); 
        
        if (tipo === 'PD')
            dispatch( startDelRevision( id )); 
    }
    
    const ApplyRevision = async (tipo, id) => {
        if (tipo === 'UD')
            dispatch( startApplyRevisionUnidadDidactica( id )); 
        
        if (tipo === 'PS')
            dispatch( startApplyRevisionPlanificacionSemanal( id )); 
        
        if (tipo === 'PD')
            dispatch( startAppRevision( id )); 
    }
    return { Listar, docente, AddRevision, DeleteRevision, ApplyRevision };
}