import React, { useState } from "react";
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import LinearProgress from '@material-ui/core/LinearProgress';

export const VisorReportes = ({title, urlReporte, CargarTerminar, Regresar = () => {}, verBotonRegresar = false}) => {
  const { altoContenedor } = useSelector( state => state.auth);
  const [cargando, setCargargando] = useState( true );

  const detenerLoding= () => {
    if (verBotonRegresar)
      setCargargando(false)
    else
      CargarTerminar()
  }

  return (
    <Grid container component="main" spacing={3}>
      <Grid item xs={ 12 }
                 style={{ marginTop: 0, 
                          paddingLeft: 10, 
                          paddingBottom: 0, 
                          minHeight: 550 }} >
          { verBotonRegresar &&
          <>
          <Tooltip title={ `Regresar` }>
            <IconButton variant="contained" 
                        color="primary" 
                        style ={{padding:'3px'}}
                        fullWidth
                        onClick={ Regresar}>
                <ArrowBackIosSharpIcon/>
            </IconButton>
        </Tooltip>
        {cargando && <Grid item xs={12} style={{ marginTop: 20, marginRight: 0 }}>
                                 <LinearProgress />
                              </Grid>}
        </>
        }
        <iframe title= { title } 
                src = { urlReporte } 
                onLoad={ detenerLoding } 
                width="100%" 
                height="98%" />
      </Grid>
    </Grid>
  );
}
