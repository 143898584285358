import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { OpenModalCalif, 
         startCursosDocente, 
         startDisciplinasCursoDocente,
         startAlumnosActa, 
         startAddActa,
         CerrarActaCalificacion} from '../state/actions/actionsCalificaciones';

const StateInitial = () => {
    const { actaActiva } = useSelector( state => state.calificaciones );

    const stateInitial = { 
                corte : actaActiva.idCorteEvaluativo,
                docente : actaActiva.idProfesor, 
                curso : actaActiva.idCurso, 
                disciplina : 0,
                alumnos: []  }

    return stateInitial;
}
const validationSchema = Yup.object({
            disciplina: Yup.number().required().min(1, "Seleccione la disciplina"),
            curso: Yup.number().required().min(1, "Seleccione el curso"),
            docente: Yup.number().required().min(1, "Seleccione el Docente"),
            corte: Yup.number().required().min(1, "Seleccione el Corte"),
         });

export const useCalificaciones = ( initialState = StateInitial()) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initialState);

    const handleInputChange = ( { target } ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : target.value
        });
    }
    const { altoContenedor } = useSelector( state => state.auth );
    const { disciplinas, cursos, actaActiva, openModalCalif } = useSelector( state => state.calificaciones );
    
    const [confirmOpen, setConfirmOpen] = useState( false );
    
    useEffect(() => {
        if ( actaActiva.id > 0) 
            CargarActa();
        
        return () => {
            dispatch( CerrarActaCalificacion() );
        }
    }, [ actaActiva ]);

    useEffect(() => {
        const cargarCursos = () => {
            if ( formValues.docente > 0 )
                dispatch( startCursosDocente( formValues.docente ) );
        }
        cargarCursos();
    }, [ formValues.docente ]);

    useEffect(() => {
        const cargarDisciplinas = () => {
            if ( formValues.curso > 0 && formValues.docente > 0 )
                dispatch( startDisciplinasCursoDocente( formValues.docente, formValues.curso ) );

        }
        cargarDisciplinas();
    }, [ formValues.curso ]);

    useEffect(() => {
        const CambioDisciplina = () => {
            if ( actaActiva.id > 0 && formValues.disciplina > 0 ) { 
                ActualizarDetalle();
            }
        }
        CambioDisciplina();
    }, [ formValues.disciplina ]);

    const ActualizarDetalle = () => dispatch( startAlumnosActa( actaActiva.id, formValues.disciplina ) );

    const CargarActa =  () => {
        const { idProfesor, idCurso } = actaActiva;
        
        dispatch( startDisciplinasCursoDocente( idProfesor, idCurso ) );
        
        if ( actaActiva.id > 0 && formValues.disciplina > 0)
            ActualizarDetalle();
    }

    const ValidarActaCal = () => {
        try {
            const valid = validationSchema.validateSync(formValues) 
            
            if (valid)
                GuardarActa();
            else
                return false;
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }
    
    const GuardarActa = async () => {
        if ( ( actaActiva.id > 0 && formValues.disciplina > 0 ) 
            || ( formValues.corte > 0 && formValues.docente > 0 && formValues.curso > 0 && formValues.disciplina > 0) ) {
            
            const data = { acta: actaActiva.id,
                           corte: actaActiva.id > 0 ? actaActiva.idCorteEvaluativo : formValues.corte,
                           docente: actaActiva.id > 0 ? actaActiva.idProfesor : formValues.docente,
                           curso: actaActiva.id > 0 ? actaActiva.idCurso : formValues.curso,
                           disciplina: formValues.disciplina,
                           alumnos: formValues.alumnos };
            
            dispatch( startAddActa( data ));

        }
    }

    const UpdateOpenModal = ( valor ) => dispatch( OpenModalCalif( valor ));

    return { altoContenedor, UpdateOpenModal, openModalCalif, confirmOpen, setConfirmOpen, actaActiva, formValues, cursos, disciplinas, handleInputChange, GuardarActa, ValidarActaCal };
}