import { types } from "../types/types";

const rowActaInicialPlaniDiaria = {
        id: 0,    
        planificacionSemanalDetalleId: 0,
        familiaValor: "", 
        usuario: 0 };

const rowActividadActiva = {
    id: 0,    
    planificacionDiariaId: 0,
    categoria: "", 
    titulo: "",
    actividad: "" };
        
const initialStatePD = { actaPlaniDiaria: rowActaInicialPlaniDiaria,
                         openModalPlaniDiaria: false,
                         contenidos: [],
                         cursos: [],
                         actividades: [],
                         revisionesDiaria: [],
                         actividadActiva: rowActividadActiva, }

export const reducerPlanificacionDiaria = ( state = initialStatePD, action ) => {
    switch (action.type) {
        /////// DATA
        case types.dataCargarContenidos:
            return { ...state,
                    contenidos: action.payload.contenidos,
                    cursos: action.payload.cursos};
        case types.dataAddNewActividad:
            return { ...state,
                     actividadActiva: rowActividadActiva,};
        case types.dataLoadActividad:
            return { ...state,
                     actividadActiva: {...(state.actividades.filter(item => item.id == action.payload))[0]},};
        case types.dataCargarPlanificacionDiaria:
            return { ...state,
                     actaPlaniDiaria: action.payload.planiDiaria,
                     actividades: action.payload.actividades,
                     contenidos: action.payload.contenidos,
                     cursos: action.payload.cursos,
                     revisionesDiaria: action.payload.revisiones,};
        case types.dataPlanificacionDiariaActiva:
            return { ...state,
                actaPlaniDiaria: action.payload, };
        case types.dataAddDetallePlanificacionDiaria:
            return { ...state,
                     actividades: [ ...state.actividades, 
                                    action.payload ],
                     actividadActiva: action.payload,}
        case types.dataUptDetallePlanificacionDiaria:
            return { ...state,
                     actividades: [...(state.actividades.filter(row => (row.id !== action.payload.id ))), action.payload ],
                     actividadActiva: { ...state.actividadActiva, ...action.payload }};
        case types.dataAddRevisionPlanificacionDiaria:
            return { ...state,
                     revisionesDiaria : [ ...state.revisionesDiaria, 
                                          action.payload ],}
        case types.dataDelRevisionPlanificacionDiaria:
            return { ...state,
                     revisionesDiaria : state.revisionesDiaria.filter(x => x.id != action.payload) ,}
        case types.dataUpdRevisionPlanificacionDiaria:
            return { ...state,
                     revisionesDiaria: state.revisionesDiaria.map( item => item.id === action.payload ? {...item, aplicado: true}
                                        : item )};
        case types.dataDeleteDetallePlanificacionDiaria:
            return { ...state,
                     actividades: state.actividades.filter(x => x.id != action.payload),
                     actividadActiva: rowActividadActiva,}
        /////// CERRAR
        case types.dataCerrarActaPlanificacionDiaria:
            return { ...initialStatePD};
        /////// UI
        case types.uiOpenModalPlaniDiaria:
            return { ...state,
                     openModalPlaniDiaria: action.payload };
        default:
            return state;
    }
 }