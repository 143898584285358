import { types } from '../types/types';
import { toast } from 'react-toastify';

import { getPlanificacionDiaria,
         getContenidos,
         postPlanificacionDiaria,
         postDetallePlanificacionDiaria,
         delDetallePlanificacionDiaria,
         postRevision,
         delRevision,
         postCerrarActaPD,
         updRevision} from '../../servicios/planificacionDiariaService';

const notify = (tipo, texto, corto)  => {
    let  duracion = corto ? 1500 : 5000;
    
    if ( tipo === 1 )
        toast.success( texto, { autoClose : duracion } );
    else
        toast.error( texto, { autoClose : duracion } );
};
////////////////////////////////////////////////////////////
export const OpenModalPlaniDiaria = ( data ) => ({
    type: types.uiOpenModalPlaniDiaria,
    payload: data
})

/////////////////////////////////////////////////////////
export const startCargarPlanificacionDiaria = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getPlanificacionDiaria(id);
            console.log(data)
            if ( status === 200 || status === 201 )
                dispatch( PlanificacionDiairia( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
const PlanificacionDiairia = ( data ) => ({
    type: types.dataCargarPlanificacionDiaria,
    payload: data
})

/////////////////////////////////////////////////////////
export const startLoadPlaniDiaria = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getContenidos(id);

            if ( status === 200 || status === 201 )
                dispatch( LoadContenidos( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
const LoadContenidos = ( data ) => ({
    type: types.dataCargarContenidos,
    payload: data
})
/////////////////////////////////////////////////////////
export const CerrarActaPlanificacionDiaria = () => ({
    type: types.dataCerrarActaPlanificacionDiaria,
})
//////////////////////////////////////////////////////////////
export const startAddPlanificacionDiaria = ( data, detalle ) => {
    return async ( dispatch ) => {
        try {
            const { status, data: response }  = await postPlanificacionDiaria( data );
            
            if ( status === 200 || status === 201 )
                if (detalle.planificacionDiariaId === 0){
                    detalle.planificacionDiariaId = response.id;

                    dispatch(startAddDetallePlanificacionDiaria(detalle))
                }
                
                dispatch( ActualizarPlanificacionDiariaActiva( response ));
        } catch (err) {
            console.log( err );
        }
    }
}
export const ActualizarPlanificacionDiariaActiva = ( data ) => ({
    type: types.dataPlanificacionDiariaActiva,
    payload: data
})

export const startUpdPlanificacionDiaria = ( data ) => {
    return async ( dispatch ) => {
        try {
            const { status, data: response }  = await postPlanificacionDiaria( data );
            
            if ( status === 200 || status === 201 )
                dispatch( ActualizarPlanificacionDiariaActiva( response ));
        } catch (err) {
            console.log( err );
        }
    }
}
////////////////////////////////////////////////////////////
export const startAddDetallePlanificacionDiaria = ( detalle, esActualizacion = false ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postDetallePlanificacionDiaria( detalle );    
            
            if ( status === 200)
                if (esActualizacion)
                    dispatch( UptDetallePlanificacionDiaria( data ));
                else 
                    dispatch( AddDetallePlanificacionDiaria( data ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AddDetallePlanificacionDiaria = ( data ) => ({
    type: types.dataAddDetallePlanificacionDiaria,
    payload: data 
})
const UptDetallePlanificacionDiaria = ( data ) => ({
    type: types.dataUptDetallePlanificacionDiaria,
    payload: data 
})
/////////////////////////////////////////////////////////////
export const startDelDetallePlanificacionDiaria = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await delDetallePlanificacionDiaria( id );    

            if ( status === 200)
                dispatch( DeleteDetallePlanificacionDiaria( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeleteDetallePlanificacionDiaria = ( data ) => ({
    type: types.dataDeleteDetallePlanificacionDiaria,
    payload: data 
})

export const AddNewActivity = () => ({
    type: types.dataAddNewActividad
})

export const LoadActivity = (id) => ({
    type: types.dataLoadActividad,
    payload: id
})
/////////////////////////////////////////////
export const startAddRevision = ( datos ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postRevision( datos );    

            if ( status === 200)
                dispatch( AddRevision( data ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AddRevision = ( data ) => ({
    type: types.dataAddRevisionPlanificacionDiaria,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDelRevision = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await delRevision( id );    

            if ( status === 200)
                dispatch( DelRevision( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DelRevision = ( id ) => ({
    type: types.dataDelRevisionPlanificacionDiaria,
    payload: id 
})
////////////////////////////////////////////////////////////
export const startAppRevision = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await updRevision( id );    

            if ( status === 200)
                dispatch( AppRevision( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AppRevision = ( id ) => ({
    type: types.dataUpdRevisionPlanificacionDiaria,
    payload: id 
})