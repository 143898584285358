import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";
import { useSelector } from "react-redux";

export const UIGrados = ({ handleInputChange, setEI, esEditable = false }) => {
  const { grados, actaActiva } = useSelector((state) => state.planificacion);
  let nombreGrado = "";
  let gradoActivo = [{"id": 0, descripcion: ""}];

  if ( grados != null && grados.length > 0 && actaActiva.id > 0 ){
    gradoActivo = grados.filter( x => x.id === actaActiva.grado );
    nombreGrado = gradoActivo[0].descripcion;
  }
  const handleSelectChange = ( valor ) => {
      const e = { target: { 'name': 'grado', 'value': valor[0].id}};
  
      handleInputChange( e );
      setEI(valor[0].ei)
  }

  return (
    <Grid item xs={12} >
      <Grid item xs={12}>
        Grado
      </Grid>
      {actaActiva.id > 0 ? (
        <TextField
          size="small"
          fullWidth={true}
          disabled={true}
          defaultValue={nombreGrado}
        />
      ) : (
        <Select
          values={gradoActivo}
          options={grados}
          onChange={ valor => handleSelectChange(valor) }
          valueField="id"
          labelField="descripcion"
          disabled={!(grados.length > 0)}
        />
      )}
    </Grid>
  );
};