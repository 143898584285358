import { combineReducers } from "redux";

import { auth } from "./auth";
import { reducerCalificaciones } from "./reducerCalificaciones";
import { reducerPlanificacion } from "./reducerPlanificacion.js";
import { reducerPlanificacionDiaria } from "./reducerPlanificacionDiaria";

import { reducer } from "./reducer.js";

export const rootReducer = combineReducers({
    auth: auth,
    general: reducer,
    calificaciones: reducerCalificaciones,
    planificacion: reducerPlanificacion,
    planificacionD: reducerPlanificacionDiaria,
})