import { types } from '../types/types';
import { toast } from 'react-toastify';

import { getCalificaciones,
         getDisciplinasCursoDocente,
         getCursosDocente,
         postAddActa,
         getDetalleActa,
         deleteAnularActa,
         putDetalleActa,
         deleteAlumnoActa } from '../../servicios/calificacionesService'
import { getDetalleObservaciones, 
         getDocentesCursosGuias, 
         putDetalleObservacion, 
         deleteObservaciones,
         postAddObservaciones } from '../../servicios/observacionesService';

export const notify = (tipo, texto, corto)  => {
    let  duracion = corto ? 1500 : 5000;
    
    if ( tipo === 1 )
        toast.success( texto, { autoClose : duracion } );
    else
        toast.error( texto, { autoClose : duracion } );
};
////////////////////////////////////////////////////////////
export const startAddActa = ( data ) => {
    return async ( dispatch ) => {
        try {
            const { status, data: response }  = await postAddActa( data );

            if ( status === 200 ) {
                dispatch( ActualizarActaActiva( response ));
                
                if ( data.acta === 0 ) // si el acta no existe
                    dispatch( AddActaLista( response ));
                
                notify( 1, 'Disciplina registrada en esta acta!!!' );
            }
        } catch (err) {
            notify( 0, 'Se presento un error. ', true );
        }
    }
}
const AddActaLista = ( data ) => ({
    type: types.dataAddActaCalificaciones,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDisciplinasCursoDocente = ( docente, curso ) => {
    return async ( dispatch ) => {
        try{
            const { status, data } = await getDisciplinasCursoDocente( docente, curso );
            if ( status === 200)
                dispatch( disciplinasCursoDocente( data ));
        } catch(err) {
        }
    }
}
const disciplinasCursoDocente = ( data ) => ({
    type: types.dataDisciplinasCursoDocente,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startCursosDocente = ( docente ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getCursosDocente( docente );
            if ( status === 200)
                dispatch( cursosDocente( data ));
        } catch (err) {}
    }
}
const cursosDocente = ( data ) => ({
    type: types.dataCursosDocente,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startAlumnosActa = ( id, disciplina ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getDetalleActa( id, disciplina );

            if ( status === 200)
                dispatch( alumnosActa( data ));
        } catch (err) {}
    }
}
const alumnosActa = ( data ) => ({
    type: types.dataAlumnosActa,
    payload: data
})
////////////////////////////////////////////////////////////
export const startCursoGuiado = ( docente ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getDocentesCursosGuias ( docente );
            if ( status === 200)
                dispatch( cursoGuiado( data.cursos ));
        } catch (err) {}
    }
}
const cursoGuiado = ( data ) => ({
    type: types.dataCargaCursoGuiado,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDetalleObservacaciones = ( obj ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getDetalleObservaciones( obj );

            if ( status === 200)
                dispatch( DetalleObservacaciones( data ));
        } catch (err) {}
    }
}
const DetalleObservacaciones = ( data ) => ({
    type: types.dataCargaDetalleObservaciones,
    payload: data 
})
/////////////////////////////////////////////////////////////
export const startCargarCalificaciones = ( docente ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getCalificaciones( docente );

            if ( status === 200)
                dispatch( cargarCalificaciones( data ));
        } catch (err){}
    }
}
const cargarCalificaciones = ( data ) => ({
    type: types.dataCargarCalificaciones,
    payload: data 
})
export const CerrarCalificaciones = (  ) => ({ type: types.dataCerrarCalificaciones })
export const CerrarActaCalificacion = (  ) => ({ type: types.dataCerrarActaCalificacion })
////////////////////////////////////////////////////////////
export const startUpdateObservacion = ( row ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await putDetalleObservacion( row.id, row.observaciones );

            if ( status === 200) {
                dispatch( updateObservacion( row ));
                notify( 1, 'Observacion Actualizada!!!', true );
            }                
        } catch (err) {
            notify( 0, 'Se presento un error' );
        }
    }
}
const updateObservacion = ( row ) => ({
    type: types.dataUpdateObservacion,
    payload: row
})
////////////////////////////////////////////////////////////
export const startUpdateCalificacion = ( row ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await putDetalleActa( row.id, row.nota );

            if ( status === 200) {
                //dispatch( updateCalificacion( row ));
                notify( 1, 'Nota Actualizada!!!', true );
            }
        } catch (err) {
            notify( 0, 'Se presento un error' );
        }
    }
}
/*const updateCalificacion = ( row ) => ({
    type: types.dataUpdateCalificacion,
    payload: row
})*/
////////////////////////////////////////////////////////////
export const startDeleteObservacion = ( row ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deleteObservaciones( row.id );

            if ( status === 200) {
                dispatch( DeleteObservacion( row ));
                notify( 1, 'Observacion Quitada!!!', true );
            }
        } catch (err) {
            notify( 0, 'Se presento un error' );
        }    
    }
}
const DeleteObservacion = ( row ) => ({
    type: types.dataDeleteObservacion,
    payload: row
})
////////////////////////////////////////////////////////////
export const startDeleteCalificacion = ( id, matricula, disciplina ) => {
    return async ( dispatch ) => {
        try {
            const { status }  = await deleteAlumnoActa( id, matricula, disciplina );

            if ( status === 200) {
                dispatch( deleteCalificacion( matricula ));
                notify( 1, 'Alumno quitado del acta!!!', true );
            }
        } catch (err) {
            notify( 0, 'Se presento un error' );
        }            
    }
}
const deleteCalificacion = ( matricula ) => ({
    type: types.dataDeleteCalificacion,
    payload: matricula
})
////////////////////////////////////////////////////////////
export const startAnularActaCalificacion = ( acta ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deleteAnularActa( acta );

            if ( status === 200 ) {
                dispatch( AnularActaCalificacion( acta ));
                notify( 1, 'Acta Anulada Satisfactoriamente' );
            }
        } catch (err) {
            notify( 0, 'Se presento un error', true );
        }
    }
}
const AnularActaCalificacion = ( id ) => ({
    type: types.dataAnularActaCalificacion,
    payload: id
})
////////////////////////////////////////////////////////////
export const CerrarDetalleObservaciones = (  ) => ({
    type: types.dataCerrarDetalleObservaciones,
})

export const ActaNueva = (  ) => ({
    type: types.actaActivaInicial,
})
             
export const ActualizarActaActiva = ( acta ) => ({
    type: types.actaActivaActualizar,
    payload: acta
})

export const OpenModalCalif = ( data ) => ({
    type: types.uiOpenModalCalif,
    payload: data
})

export const OpenModalObser = ( data ) => ({
    type: types.uiOpenModalObser,
    payload: data
})
/////////////////////////////////////////////////////
export const startAddObservaciones = ( data ) => {
    return async ( dispatch ) => {
        try {
            const { status }  = await postAddObservaciones( data );

            if ( status === 200 ) {
                dispatch( startDetalleObservacaciones( data ))

                notify( 1, 'Observaciones registradas!!!' );
            }
        } catch (err) {
            notify( 0, 'Se presento un error. ', true );
        }
    }
}