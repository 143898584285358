import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-dropdown-select";
import { useSelector } from 'react-redux';

export const UIPrincipiosValores = ({ principiosValores, handleInputChange }) => {
  const { principiosValores : data } = useSelector( state => state.planificacion );
  let valor = "";

  if (data.length > 0)
    data.map(item => valor += item.descripcion + ', ');

  const handleSelectChange = ( valor ) => {
    if ( valor.length > 0) {
      console.log("principiosValor", valor)
      const e = { target: { 'name': 'principiosValores', 'value': valor}};
      handleInputChange( e );
    }
  }
  return (
    <Grid item xs={12} >
      <Grid item xs={12} >
        Principios Valores
      </Grid>
        <Select multi
                options={data}
                values={principiosValores}
                valueField="id"
                labelField="descripcion"
                searchBy="descripcion"
                onChange={ valor => handleSelectChange(valor) }
                disabled={!(data.length > 0)}/>
    </Grid>
  );
};