import React, { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { DialogTitle } from '../../comunes/DialogTitle';
import { EncabezadoActaPlanificacionMensual } from './EncabezadoActaPlanificacionMensual';
import { AddDetalleUnidadDidactica } from './AddDetalleUnidadDidactica';
import { RevisionesUnidadDidactica } from '../RevisionesUnidadDidactica';

import { usePlanificacion } from '../../../hooks/usePlanificacionUnidadDidactica';

import '../style.css';

export const NuevaPlanificacionMensual = () => {
    const [tapSeleccionada, setTapSeleccionada] = useState('encabezado');
    const { altoContenedorBase, openModalPlani, UpdateOpenModalPlani,
            actaActiva, RevisionesNoAplicadasLength} = usePlanificacion(); 

    return (
        <Dialog onClose = { () => UpdateOpenModalPlani(false) } 
                disableEscapeKeyDown
                aria-labelledby = "customized-dialog-title" 
                open = { openModalPlani }
                maxWidth = 'lg'>
            <DialogTitle id = "customized-dialog-title" 
                         onClose = { () => UpdateOpenModalPlani(false) }>
                { actaActiva.id === 0 && "Nueva" } Unidad Didactica
            </DialogTitle>
            <DialogContent dividers
                           style={{ minHeight: `${altoContenedorBase}px`, 
                                    maxHeight: `${altoContenedorBase}px`}}>
                    <CssBaseline />
                    
                    <Tabs value={tapSeleccionada} 
                          onChange={ (e, nv) => setTapSeleccionada(nv) }
                          style={{backgroundColor:'#7EC6FB'}} >
                        <Tab value="encabezado"
                             label="Encabezado"
                             wrapped/>
                        {actaActiva.id > 0 && <Tab value="contenido"
                                                   label="Contenido"
                                                   wrapped/>}
                        {actaActiva.id > 0 && <Tab value="revisiones" 
                                                   color="secondary"
                                                   label={<Badge badgeContent={RevisionesNoAplicadasLength()}
                                                                 color="secondary">
                                                                Revisiones
                                                          </Badge> } />}
                    </Tabs>
                    { tapSeleccionada === 'encabezado'
                        ? <EncabezadoActaPlanificacionMensual/> 
                        : tapSeleccionada === 'contenido' && actaActiva.id > 0
                            ? <AddDetalleUnidadDidactica/>
                            : actaActiva.id > 0 && <RevisionesUnidadDidactica tipo = 'UD'/>
                    }
            </DialogContent>
        </Dialog>
    );
}