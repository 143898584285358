import { types } from "../types/types";

const initialState = { docentes: [], 
                       cortes: [], 
                       cursos: [],
                       tiposReportes: [ { id:1, descripcion:'Calificaciones' }, 
                                        { id:2, descripcion:'Conductas'},
                                        { id:3, descripcion:'Calificaciones Reprobadas'},
                                        { id:4, descripcion:'Mejores Calificaciones'}],
                       grupos: [ { id:1, descripcion:'por Curso' }, 
                                 { id:2, descripcion:'por Grado'},
                                 { id:3, descripcion:'por Modalidad'},
                                 { id:4, descripcion:'por Turno'}],
                       tops: [ { id:10, descripcion:'10' }, 
                               { id:5, descripcion:'5'},
                               { id:3, descripcion:'3'},
                               { id:1, descripcion:'1'}],
                       semanas: [ { value:'Semana 1', label:'Semana 1'}, 
                                  { value:'Semana 2', label:'Semana 2'},
                                  { value:'Semana 3', label:'Semana 3'},
                                  { value:'Semana 4', label:'Semana 4'},
                                  { value:'Semana 5', label:'Semana 5'}] }

export const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        /////// DATA
        case types.dataCargaInicial:
            return { ...state,
                     cortes: action.payload.cortes,
                     docentes : action.payload.docentes };
        case types.dataCargarCursos:
            return { ...state,
                     cursos: action.payload };
        default:
            return state;
    }
 }