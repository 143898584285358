import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-dropdown-select";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import ConfirmDialog from '../../UI/ConfirmDialog'
import { FechaCursos } from './FechaCursos'
import { UIBotonera } from '../../UI/UIBotoneras'
import { usePlanificacionDetalle } from '../../../hooks/usePlanificacionSemanalDetalle'

export const AddDetallePlaniSemanal = ({Validar, data}) => {
	const [confirmOpen, setConfirmOpen] = useState(false);
	const { LoadContenido, AddNewContenido, AddContenido, formValues, handleInputChange, handleSelectChange, unidades, temasView, actaPlaniSemanal, contenidoSemanal, NuevaFecha, QuitarFecha, DelContenido} = usePlanificacionDetalle(); 

	const { unidad, tema, contenido, indicadorLogro, estrategiaAplicar } = formValues;

	const ValidarActa = () => {
		setConfirmOpen(Validar());
	}
    return (
        <Grid container style={{marginTop:0}} >
			<Grid item xs={12} style={{marginTop:0}} className="subtitulo" >
				<Grid container spacing={2}
					component="main" style={{marginTop:0, marginBottom:15}} >
					
					<Grid item xs={12} md={6}>
						Unidad
						<Select options={unidades}
								onChange={ valor => handleSelectChange(valor, 'unidad') }
								values={unidad}
								valueField="id"
								labelField="descripcion"
								searchBy="descripcion"/>
					</Grid>
					<Grid item xs={12} md={6}>
						Tema
						<Select options={temasView}
								onChange={ valor => handleSelectChange(valor, 'tema') }
								values={tema}
								valueField="id"
								labelField="descripcion"
								searchBy="descripcion"/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField  size="small"
									fullWidth
									multiline
									minRows={1}
									inputProps={{ maxLength: 150 }}
									label="Contenido"
									value={contenido}
                                    name="contenido"
                                    onChange={ e => handleInputChange( e)}/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField	size="small"
									fullWidth
									multiline
									minRows={1}
									inputProps={{ maxLength: 150 }}
									label="Indicador Logro"
									value={indicadorLogro}
                                    name="indicadorLogro"
                                    onChange={ e => handleInputChange( e)}/>
					</Grid>
					<Grid item xs={12}>
						<TextField	size="small"
									fullWidth
									inputProps={{ maxLength: 250 }}
									label="Estrategia Aplicar"
									value={estrategiaAplicar}
                                    name="estrategiaAplicar"
                                    onChange={ e => handleInputChange( e)}/>
					</Grid>
					<Grid item xs={12} md={6}>
						<FechaCursos NuevaFecha ={ NuevaFecha }
									 QuitarFecha={ QuitarFecha } />
					</Grid>
					<Grid item xs={12} md={6} className="margenesGrid subtitulo" 
							   style={{textAlign:'center', alignSelf:'center'}}>
						{ actaPlaniSemanal.id === 0 
						? <Tooltip title="Agregar">
								<IconButton	edge="start"
											color="inherit"
											aria-label="menu"
											onClick={ ValidarActa }>
									<AddCircleIcon fontSize="large" />
								</IconButton>
						  </Tooltip>
						: <UIBotonera lista = { contenidoSemanal }
									  functionLoad = { LoadContenido }
									  functionAddNew = { AddNewContenido }
									  functionAdd = { AddContenido }
									  functionDel = { DelContenido}
									  titleButton = "Contenido"
									  opcion = {3}/>
						}
					</Grid>
				</Grid>
			</Grid>
			<ConfirmDialog 
                title = {`¿Confirma agregar contenido a la Planificacion Semanal?`} 
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { () => AddContenido(data) } />
        </Grid>
    );
};