import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
import Chip from '@material-ui/core/Chip';

import { NuevaActa } from "./NuevaActa"
import ConfirmDialog from '../UI/ConfirmDialog'
import { Observaciones } from "./Observaciones"
import { ActaNueva, 
         ActualizarActaActiva, 
         startCargarCalificaciones, 
         CerrarCalificaciones, 
         OpenModalCalif, 
         OpenModalObser, 
         startAnularActaCalificacion } from '../../state/actions/actionsCalificaciones';

export const Calificaciones = () => {
    const dispatch = useDispatch();
    const { cortes } = useSelector( state => state.general );
    const { calificaciones, openModalCalif, openModalObser } = useSelector( state => state.calificaciones );
    const { docente, altoContenedor } = useSelector( state => state.auth );

    const [confirmOpen, setConfirmOpen] = useState( false );
    const [actaAnular, setActaAnular] = useState( 0 );
    
    useEffect(() => {
        dispatch( startCargarCalificaciones( docente ));
        
        return () => {
            dispatch( CerrarCalificaciones() );
        }
    }, [dispatch, docente])

    const NuevaActaCala = () => {
        dispatch( OpenModalCalif(true) );
        dispatch( ActaNueva() );
    } 
    const EditActa = ( rowData ) => {
        dispatch( OpenModalCalif(true) );
        dispatch( ActualizarActaActiva( rowData ) );
    }
    const AnularActa = async () => {
        if ( actaAnular > 0)
            dispatch( startAnularActaCalificacion( actaAnular ))
    }

    const VerificarCorte = ( data ) => cortes.filter( row => row.id === data.idCorteEvaluativo ).length > 0 

    const columns = [ { title: 'Corte Evaluativo', field: 'corteEvaluativo', width: 100 },
                      { title: 'Docente', field: 'docente', width: 200 },
                      { title: 'Curso', field: 'curso' , width: 200},
                      { title: 'Cerrada', field: 'cerrado', width: 15 ,
                           render: rowData => <Chip label={rowData.cerrado ? 'Si'  : 'No'} 
                                                    color={rowData.cerrado ? 'primary'  : 'default'} 
                                                    size="small" />},
                      { title: 'Extraordinario', field: 'extraordinario', width: 5,
                           render: rowData => <Chip label= {rowData.extraordinario ? 'Si'  : 'No'} 
                                                    color= {rowData.extraordinario ? 'secondary'  : 'primary'} 
                                                    size="small"/>} ];

    return (
        <>
            <MaterialTable
                title = "Listado Calificaciones"
                columns = { columns }
                data = { calificaciones }
                options = {{
                    filtering: true,
                    actionsColumnIndex: -1,
                    paginationType: 'stepped',
                    padding: 'dense',
                    maxBodyHeight: `${ (altoContenedor - 200) }px`,
                    pageSize: 30,
                    pageSizeOptions: [30],
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Buscar',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros!'
                    }
                }}
                actions = {[
                    { icon: 'post_add_icon',
                        tooltip: 'Nueva Acta Calificaciones',
                        isFreeAction: true,
                        hidden: !(cortes.length > 0),
                        onClick: () => NuevaActaCala() 
                    },
                    { icon: 'class_icon',
                        tooltip: 'Observaciones Boletas Calificaciones',
                        isFreeAction: true,
                        hidden: !(cortes.length > 0),
                        onClick: () => dispatch( OpenModalObser( true) )
                    },
                    rowData => ({ icon: 'edit',
                                  tooltip: 'Actualizar Acta',
                                  hidden: !VerificarCorte(rowData),
                                  onClick: (event, rowData) => EditActa( rowData ) }),
                                ]}
                                />
            {/*
                iconProps: { color: 'error' },
                tooltip: 'Anular Acta',
                hidden: !VerificarCorte(rowData),
                onClick: (event, rowData) => { setActaAnular( rowData.id );
                setConfirmOpen( true ); }})
                
                rowData => ({ icon: 'cancel',*/
                }
            { openModalCalif && <NuevaActa/> }

            { openModalObser && <Observaciones/> }

            <ConfirmDialog 
                title = { `¿Confirma anular esta Acta de Calificaciones?` }
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { AnularActa } />
        </>
    )
}