import { types } from "../types/types";
import moment from 'moment';

const rowActaInicial = { 
    id: 0, 
    docente: 0, 
    grado: 0,
    ei: false,
    disciplina: 0, 
    periodoProgramado: "", 
    fecha: moment(new Date()).format('YYYY-MM-DD'), 
    fechaNoEPI: "", 
    familiaValores : [],
    principiosValores : [],
    indicadoresLogro: [],
    unidadActiva: {
        planificacionMensualId : 0, 
        id : 0, 
        unidad : '', 
        competenciaGrado : '', 
        ejeTranversal : '', 
        observaciones : ''
    },
    unidades: [],
    temaActivo: {
        planificacionMensualDetalleId : 0,
        id : 0,
        tema: '',
        conceptual: '',
        procedimental: '',
        actitudinal: '',
        tecnicaEvaluacion: '',
        evidenciaAprendizaje: '',
        criterioEvaluacion: '',
        fechaInicio : moment(new Date()).format('YYYY-MM-DD'),
        fechaFinalizacion : moment(new Date()).format('YYYY-MM-DD'),
        horasClase : 0
    },
    temas: [],
    revisiones: [],
    usuario: 0   };

const rowActaInicialPlaniSemanal = {
    planificacionMensualId: 0,
    id: 0, 
    semana: 0, 
    observaciones: "", 
    usuario: 0 };

const rowActaInicialPlaniDiaria = {
        planificacionMensualId: 0,
        id: 0, 
        noSemana: 0, 
        observaciones: "", 
        usuario: 0 };

const contentInicial = {
        planificacionSemanalId :  0, 
        id : 0, 
        unidad : [],
        tema : [], 
        contenido : '',
        indicadorLogro : '', 
        estrategiaAplicar: '',
        fechasCursos: [],}
    
const initialState = {  unidadesDidacticas: [],
                        ei: false,
                        actaActiva: rowActaInicial,
                        openModalPlani: false,
                        familiaValores: [],
                        principiosValores : [],
                        grados: [],
                        disciplinas: [],
                        actaPlaniSemanal: rowActaInicialPlaniSemanal,
                        openModalPlaniSemanal: false,
                        vstUnidadDidactica: {},
                        contenidoSemanal: [],
                        revisionesSemanal: [],
                        cursos: [],
                        unidades: [],
                        temas: [],
                        temasView: [],
                        contenidoActivo: contentInicial,

                        actaPlaniDiaria: rowActaInicialPlaniDiaria,
                        openModalPlaniDiaria: false,
                        contenidoDiario: [],
                        revisionesDiaria: [],
                        fechas: [],
                     }

export const reducerPlanificacion = ( state = initialState, action ) => {
    switch (action.type) {
        case types.dataCargarUnidadesDidacticas:
            return { ...state,
                     unidadesDidacticas: action.payload,};
        /////// planificacion mensual
        case types.dataCargarUnidadDidactica:
            return { ...state,
                     ei: action.payload.ei,
                     grados: action.payload.grados,
                     disciplinas: action.payload.disciplinas,
                     familiaValores : action.payload.familiaValores,
                     actaActiva: action.payload.unidadDidactica,}
        case types.dataFamiliaValores:
            return { ...state,
                    familiaValores: action.payload,};
        case types.dataPrincipiosValores:
            return { ...state,
                    principiosValores: action.payload,};
        case types.dataAddActaListaPlanificacionMensual:
            return { ...state,
                     actaActiva: { ...state.actaActiva, ...action.payload },
                     unidadesDidacticas: [...state.unidadesDidacticas, action.payload.vista ]}
        case types.dataUpdActaListaPlanificacionMensual:
            return {...state,
                    actaActiva: { ...state.actaActiva, ...action.payload },
                    unidadesDidacticas: [...(state.unidadesDidacticas.filter(row => (row.id !== action.payload.vista.id ))), action.payload.vista ]};
        case types.dataActualizarEI:
            return { ...state,
                     ei: action.payload,}
        ////////////////////////////////////////////////////
        case types.dataDeletePlanificacionMensual:
            return { ...state,
                     unidadesDidacticas: state.unidadesDidacticas.filter( row => (row.id !== action.payload ) ) }
        case types.dataGradosDocente:
            return { ...state,
                     grados: action.payload};
        case types.dataDisciplinasGradoDocente:
            return { ...state,
                     disciplinas: action.payload};
        case types.dataAddIndicadorLogro:
            return { ...state,
                     actaActiva : { ...state.actaActiva, 
                                    indicadoresLogro: [ ...state.actaActiva.indicadoresLogro, 
                                            { "id": action.payload.id,
                                              "descripcion": action.payload.indicadorLogro,} ] }};
        case types.dataDeleteIndicadorLogro:
            return { ...state,
                        actaActiva : {...state.actaActiva,
                                      indicadoresLogro: state.actaActiva.indicadoresLogro.filter( row => (row.id !== action.payload ) )}};
        /*case types.AddContenidoUnidad:
            return { ...state,
                    contenidoDetalle: [ ...state.contenidoDetalle, 
                        { "id": action.payload.contenido.id,
                          "descripcion": action.payload.contenido.contenido,}  ],
                    contenido: action.payload.contenidos};
        case types.dataDeleteContenidoUnidad:
            return { ...state,
                        contenidoDetalle: state.contenidoDetalle.filter( row => (row.id !== action.payload ) )};
        case types.dataAddDetallePlanificacionMensual:
            return { ...state,
                    contenido: [ ...state.contenido, action.payload ],
                    idDetalle: action.payload.id};
        case types.dataDeleteDetallePlanificacionMensual:
            return { ...state,
                    contenido: state.contenido.filter( row => (row.id !== action.payload ) )};*/
        case types.dataLoadUnit:
            return { ...state,
                     actaActiva: {...state.actaActiva,
                                  unidadActiva: {...(state.actaActiva.unidades.filter( row => (row.id === action.payload )))[0]},
                                  temaActivo: { ...state.actaActiva.temaActivo, ...rowActaInicial.temaActivo}
                                }};
        case types.dataAddNewUnit:
            return { ...state,
                     actaActiva: {...state.actaActiva,
                                  unidadActiva: { ...state.actaActiva.unidadActiva, ...rowActaInicial.unidadActiva}}};
        case types.dataAddUnidad:
            return {...state,
                    actaActiva: {...state.actaActiva,
                                 unidades: [...state.actaActiva.unidades, action.payload ],
                                 unidadActiva: { ...action.payload },
                                 temaActivo: { ...rowActaInicial.temaActivo}}};
        case types.dataUpdUnidad:
            return {...state,
                    actaActiva: {...state.actaActiva,
                                 unidades: [...(state.actaActiva.unidades.filter(row => (row.id !== action.payload.id ))), action.payload ],
                                 unidadActiva: { ...state.actaActiva.unidadActiva, ...action.payload }}};
        case types.dataCerrarActaPL:
            return {...state,
                    unidadesDidacticas: state.unidadesDidacticas.map(row => (row.id != action.payload ? row : {...row, esActiva: false}))};
        ////TEMAS
        case types.dataLoadTopic:
            return { ...state,
                     actaActiva: {...state.actaActiva,
                                  temaActivo: {...(state.actaActiva.temas.filter( row => (row.id === action.payload )))[0]}}};
        case types.dataAddNewTopic:
            return { ...state,
                     actaActiva: {...state.actaActiva,
                                  temaActivo: { ...state.actaActiva.temaActivo, ...rowActaInicial.temaActivo}}};
        case types.dataAddTopic:
            return {...state,
                    actaActiva: {...state.actaActiva,
                                 temas: [...state.actaActiva.temas, action.payload ],
                                 temaActivo: { ...state.actaActiva.temaActivo, ...action.payload }}};
        case types.dataUpdTopic:
            return { ...state,
                     actaActiva: { ...state.actaActiva,
                                   temas: [...(state.actaActiva.temas.filter(row => (row.id !== action.payload.id ))), action.payload ],
                                   temaActivo: { ...state.actaActiva.TemaActivo, ...action.payload }}};
        case types.dataDelTema:
            return { ...state,
                     actaActiva: { ...state.actaActiva,
                                   temas: state.actaActiva.temas.filter(x => x.id !== action.payload),
                                   temaActivo: rowActaInicial.temaActivo }};
        case types.dataDelUnidad:
            return { ...state,
                     actaActiva: { ...state.actaActiva,
                                   unidades: state.actaActiva.unidades.filter(x => x.id !== action.payload),
                                   unidadActiva: rowActaInicial.unidadActiva }};

        case types.dataAddRevisionPlanificacionMensual:
            return { ...state,
                     actaActiva : { ...state.actaActiva,
                                    revisiones: [ ...state.actaActiva.revisiones, action.payload  ] }};
        case types.dataDeleteRevisionPlanificacionMensual:
            return { ...state,
                     actaActiva : { ...state.actaActiva,
                                    revisiones: state.actaActiva.revisiones.filter( row => (row.id !== action.payload ) )}};
        case types.dataUpdateRevisionPlanificacionMensual:
            return { ...state,
                     actaActiva : { ...state.actaActiva,
                                    revisiones: state.actaActiva.revisiones.map( item => item.id === action.payload ? {...item, aplicado: true}
                                            : item )}};
        /////// planificacion semanal
        case types.dataAddDate:
            return { ...state,
                     contenidoActivo : { ...state.contenidoActivo,
                                         fechasCursos : [...state.contenidoActivo.fechasCursos, action.payload ] },
                     contenidoSemanal : state.contenidoSemanal.map( item => item.id === action.payload.idDetalle ? {...item, 
                                  fechasCursos: [...item.fechasCursos, action.payload ]}
                        : item )                  
                    };
        case types.dataDelDate:
            return { ...state,
                     contenidoActivo : { ...state.contenidoActivo,
                                         fechasCursos : state.contenidoActivo.fechasCursos.filter( item => item.id != action.payload.id ) },
                     contenidoSemanal : state.contenidoSemanal.map( item => item.id != action.payload.detalle ? item : {...item, 
                                       fechasCursos : item.fechasCursos.filter ( x => x.id != action.payload.id)} )
                    };

        case types.dataCargarPlanificacionSemanal:
            return { ...state,
                actaPlaniSemanal: action.payload.planificacionSemanal,
                //cursos: action.payload.cursos,
                //unidades: action.payload.unidades,
                //temas: action.payload.temas,
                contenidoSemanal: action.payload.detalle,
                vstUnidadDidactica: action.payload.vstUnidadDidactica,
                revisionesSemanal: action.payload.revisiones,};
        case types.dataLoadVstUnidadDidactica:
            return { ...state,
                        vstUnidadDidactica: action.payload };
        case types.dataCargarUnidadesCursos:
            return { ...state,
                     cursos: action.payload.cursos,
                     unidades: action.payload.unidades,
                     temas: action.payload.temas,
                     temasView:[],};
        case types.dataCargarTemasUnidad:
            return { ...state,
                     temasView:  state.temas.filter( it => it.unidadId === action.payload)};
        case types.dataPlanificacionSemanalActiva:
            return { ...state,
                     actaPlaniSemanal: action.payload };
        case types.dataUpdPlanificacionSemanal:
            return { ...state,
                     actaPlaniSemanal: { ...action.payload }};
        case types.dataLoadContent:
            return { ...state,
                     contenidoActivo: {...(state.contenidoSemanal.filter( row => (row.id === action.payload )))[0]}};
        case types.dataAddNewContent:
            return { ...state,
                     contenidoActivo: { ...contentInicial}};
        case types.dataAddContent: //types.dataAddDetallePlanificacionSemanal:
            return { ...state,
                     contenidoSemanal: [ ...state.contenidoSemanal, action.payload ],
                     contenidoActivo: action.payload};
        case types.dataUpdContent:
            return { ...state,
                     contenidoSemanal: [...(state.contenidoSemanal.filter(row => (row.id !== action.payload.id ))), action.payload ],
                     contenidoActivo: { ...state.contenidoActivo, ...action.payload }};
        case types.dataDelContenido:
            return {...state,
                    contenidoSemanal: state.contenidoSemanal.filter(row => (row.id !== action.payload )), 
                    contenidoActivo: contentInicial };
        case types.dataAddRevisionPlanificacionSemanal:
            return { ...state,
                     revisionesSemanal: [ ...state.revisionesSemanal, action.payload  ] };
        case types.dataDeleteRevisionPlanificacionSemanal:
            return { ...state,
                revisionesSemanal: state.revisionesSemanal.filter( row => (row.id !== action.payload ) )};
        case types.dataUpdateRevisionPlanificacionSemanal:
                return { ...state,
                    revisionesSemanal: state.revisionesSemanal.map(
                        item => item.id === action.payload
                            ? {...item, aplicado: true}
                            : item
                    )};
        /*case types.dataCargarFechas:
            return { ...state,
                    fechas: action.payload,};
        case types.dataDeleteDetallePlanificacionSemanal:
            return { ...state,
                contenidoSemanal: state.contenidoSemanal.filter( row => (row.id !== action.payload ) )};

        
        
                
        case types.dataPlanificacionDiariaActiva:
            return { ...state,
                actaPlaniDiaria: action.payload, 
               };
        case types.dataLoadDetalle:
            return { ...state,
                    idDetalle: 0,
                    contenidoDetalle: [],
                };
        /////// CERRAR
        case types.dataCerrarPlanificacion:
            return { ...state,
                unidadesDidacticas: [],};*/
        case types.dataCerrarActaPlanificacion:
            return { ...state,
                     actaActiva: rowActaInicial,
                     openModalPlani: false,
                     familiaValores: [],
                     principiosValores : [],
                     grados: [],
                     disciplinas: [],
                     //contenido: [],
                     //contenidoDetalle: [],
                     //revisiones: [],
                     //idDetalle: 0,
                    };
        case types.dataCerrarActaPlanificacionSemanal:
            return { ...state,
                    actaPlaniSemanal: rowActaInicialPlaniSemanal,
                    contenidoSemanal: [],
                    unidades: [],
                    cursos: [],
                    temas: [],
                    vstUnidadDidactica: {},
                    revisionesSemanal: [],
                    temasView: [],
                    contenidoActivo: contentInicial,}; 
        /*case types.dataCerrarActaPlanificacionDiaria:
            return { ...state,
                    fechas: [],};*/
        /////// UI
        case types.uiOpenModalPlani:
            return { ...state,
                     openModalPlani: action.payload };
        case types.uiOpenModalPlaniSemanal:
            return { ...state,
                     openModalPlaniSemanal: action.payload };
        case types.uiOpenModalPlaniDiaria:
            return { ...state,
                     openModalPlaniDiaria: action.payload };
        default:
            return state;
    }
 }