import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

export const TextShearch = ({filtro, setFiltro}) => {
    return (
        <Tooltip title="Filtrar alumnos en el detalle.">
            <TextField  value = {filtro}
                        size='small' 
                        fullWidth={true} 
                        placeholder='Buscar'
                        style={{paddingTop:0, paddingBottom:0, background: 'white'}}
                        InputProps={{ startAdornment: ( <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment> ) }}
                        onChange={ ( e ) => setFiltro( e.target.value ) }
            />
         </Tooltip>
    )
}
