import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OpenModalPlani, 
         OpenModalPlaniSemanal, 
         Unload, 
         LoadVstUnidadDidactica,
         startCargarUnidadDidactica, 
         startUnidadesDidacticas, 
         startCerrarActaPM,
         startCerrarActaPS,
         startCerrarActaPD,
         startCargarPlanificacionSemanal } from '../state/actions/actionsPlanificacion';

import { OpenModalPlaniDiaria,
         startCargarPlanificacionDiaria } from '../state/actions/actionsPlanificacionDiaria'

export const usePlanificacion = () => {
    const dispatch = useDispatch();
    const { docente, altoContenedor } = useSelector( state => state.auth );
    const { unidadesDidacticas, 
            openModalPlani, 
            openModalPlaniSemanal } = useSelector( state => state.planificacion );
    const { openModalPlaniDiaria } = useSelector( state => state.planificacionD );
    
    useEffect(() => {
        dispatch( startUnidadesDidacticas(docente) );
        return () => {
            dispatch( Unload() );
        }
    }, [])

    const CargarActa = async ( data ) => {
        if (data ) {
            await dispatch( startCargarUnidadDidactica(data.id) );
            UpdateOpenModalPlani(true);
        }
    }
    
    const CargarActaPlaniSemanal = async ( data ) => {
        if (data ) {
            await dispatch( startCargarPlanificacionSemanal(data.id) );
            UpdateOpenModalPlaniSemanal(true);
        }
    }
    
    const CargarActaPlaniDiaria = async ( data ) => {
        if (data ) {
            await dispatch( startCargarPlanificacionDiaria(data.id) );
            UpdateOpenModalPlaniDiaria(true);
        }
    }

    const CargarVstUnidadDidactica = ( data ) => {
        dispatch( LoadVstUnidadDidactica( data ))
    };
    
    const CerrarActaPM = ( id ) => dispatch( startCerrarActaPM( id ));
    
    const CerrarActaPS = ( id ) => dispatch( startCerrarActaPS( id ));

    const CerrarActaPD = ( id ) => dispatch( startCerrarActaPD( id ));

    const UpdateOpenModalPlani = ( valor ) => dispatch( OpenModalPlani( valor ));
    
    const UpdateOpenModalPlaniSemanal = ( valor ) => dispatch( OpenModalPlaniSemanal( valor ));

    const UpdateOpenModalPlaniDiaria = ( valor ) => dispatch( OpenModalPlaniDiaria( valor ));

    return { unidadesDidacticas, altoContenedor, 
             UpdateOpenModalPlani, UpdateOpenModalPlaniSemanal, UpdateOpenModalPlaniDiaria,
             openModalPlani, openModalPlaniSemanal, openModalPlaniDiaria, 
             CargarActa, CargarActaPlaniSemanal, CargarActaPlaniDiaria, CargarVstUnidadDidactica, docente,
             CerrarActaPM, CerrarActaPS, CerrarActaPD};
}