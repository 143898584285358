import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { startLoadPlaniSemanal,
         OpenModalPlaniSemanal,
         startUnidadesDidacticas,
         startUpdPlanificacionSemanal,
         CerrarActaPlanificacionSemanal } from '../state/actions/actionsPlanificacion';

const StateInitial = () => {
    const { actaPlaniSemanal, vstUnidadDidactica } = useSelector( state => state.planificacion );

    const stateInitial = {  planificacionMensualId : vstUnidadDidactica.referencia,
                            id: actaPlaniSemanal.id,
                            semana : actaPlaniSemanal.semana, 
                            observaciones : actaPlaniSemanal.observaciones, }

    return stateInitial;
}

const validationSchema = Yup.object({semana: Yup.string().required("Ingrese el numero de semana"),
                                     planificacionMensualId: Yup.number().min(1, "Seleccione la unidad didactica").required()});

export const usePlanificacion = ( initialState = StateInitial()) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initialState);
    const { docente, altoContenedor } = useSelector( state => state.auth );
    const { vstUnidadDidactica, openModalPlaniSemanal, actaPlaniSemanal,  revisionesSemanal } = useSelector( state => state.planificacion );

    const altoContenedorBase = altoContenedor - 350;

    const handleInputChange = ( { target } ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : target.value
        });
    }
    const handleSelectChange = ( valor, name ) => {
        const e = { target: { 'name': name, 'value': valor }};
        handleInputChange( e )
    }
    useEffect(() => {
        const load = () => {
            dispatch( startLoadPlaniSemanal(vstUnidadDidactica.referencia));
        }
        load()
        return () => {
            dispatch( CerrarActaPlanificacionSemanal() );
            dispatch( startUnidadesDidacticas(docente) );
        } 
    }, []);

    const ValidarActa = () => {
        try {
            return validationSchema.validateSync(formValues);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }
    
    const UpdContenido = async () => {
        if (ValidarActa()){
            const data = {...formValues,
                id : actaPlaniSemanal.id}
                
            await dispatch( startUpdPlanificacionSemanal( data ) );
        }
    }

    const UpdateOpenModalPlaniSemanal = ( valor ) => dispatch( OpenModalPlaniSemanal( valor ));

    const RevisionesNoAplicadasLength = () => {
        const revisionNoAplicado = revisionesSemanal.filter(item => !item.aplicado);

        return revisionNoAplicado.length;
    }

    return { altoContenedor, altoContenedorBase, UpdateOpenModalPlaniSemanal, 
             openModalPlaniSemanal, ValidarActa, vstUnidadDidactica, 
             formValues, handleInputChange, actaPlaniSemanal,  RevisionesNoAplicadasLength,
             UpdContenido, handleSelectChange };
}