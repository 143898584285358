import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

import { DetalleObservaciones } from './DetalleObservaciones';
import { AddButton } from '../UI/UIAddButton'
import { DialogTitle } from '../comunes/DialogTitle';
import { UICortes } from '../UI/UICortes';
import { UIDocentes } from '../UI/UIDocentes';
import { UICursos } from '../UI/UICursos';
import { UIAlumnos } from '../UI/UIAlumnos';
import { useObservaciones } from '../../hooks/useObservaciones';

export const Observaciones = () => {
    const { UpdateOpenModal, openModalObser,
            cursos, formValues, handleInputChange, GuardarActa } = useObservaciones(); 

    const { corte, docente, curso, alumnos } = formValues;

    return (
        <Dialog onClose={ () => UpdateOpenModal(false) }
                disableEscapeKeyDown
                aria-labelledby="customized-dialog-title" 
                open={ openModalObser }
                fullWidth={true}
                maxWidth='lg'>
            <DialogTitle id="customized-dialog-title" onClose={ () => UpdateOpenModal(false) }>
                Registro Observaciones
            </DialogTitle>
            <DialogContent dividers>
                <Grid container component="main" spacing={3}>
                    <CssBaseline />
                    <Grid item xs={12} md={4} style={{paddingTop:2, paddingBottom:0}} >
                        <UICortes corte = {corte} 
                                    handleInputChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={4} style={{paddingTop:5, paddingBottom:0}} >
                        <UIDocentes docente = { docente} 
                                    handleInputChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={4} style={{paddingTop:5, paddingBottom:0 }} >
                        <UICursos actaActiva = {0}
                                    cursos = { cursos }
                                    curso = { curso} 
                                    handleInputChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6} style={{paddingTop:5, paddingBottom:0 }} >
                        <UIAlumnos sinActa={true}
                                    alumnos = {alumnos}
                                    handleInputChange = { handleInputChange } />
                    </Grid> 
                    <Grid item xs={12} md={1} 
                          style={{ paddingTop:2, paddingBottom:0, textAlign:'right',
                                   alignSelf: 'flex-end', display:'flex'}}>
                        <AddButton functionAdd = {GuardarActa} 
                                   titleButton = "Agregar alumnos a las observaciones"
                                   titleConfirm = "¿Confirma agregar observaciones a este corte?"/>
                    </Grid>
                    <Grid item xs={12}>
                        <DetalleObservaciones/>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}