import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { startAddTema,
         AddNewTopic,
         LoadTopic,
         startDelTema } from '../state/actions/actionsPlanificacion';

const stateInitial = {  planificacionMensualDetalleId : 0, 
                        id : 0, 
                        tema : '', 
                        procedimental: '',
                        conceptual: '',
                        actitudinal: '',
                        tecnicaEvaluacion: '',
                        evidenciaAprendizaje: '',
                        criterioEvaluacion: '',
                        horasClase : 0,
                        fechaInicio : moment(new Date()).format('YYYY-MM-DD'),
                        fechaFinalizacion : moment(new Date()).format('YYYY-MM-DD') }

export const usePlanificacionDetalleTema = ( initialState = stateInitial) => {
    const dispatch = useDispatch();
    const {actaActiva, ei } = useSelector( state => state.planificacion );
    const [formValues, setFormValues] = useState(initialState);

    const validationSchemaTM = Yup.object({
            horasClase: Yup.number().required().min(1, "Ingrese horas clase"),
            fechaFinalizacion: Yup.date().required()
                                .min(formValues.fechaInicio,"fecha Final no puede ser menor a fecha de incio")
                                .typeError("Ingrese fecha de inicio"),
            fechaInicio: Yup.date().required().typeError("Ingrese fecha de finalizacion"),
            actitudinal: Yup.string().required("Ingrese el actitudinal"),
            procedimental: Yup.string().required("Ingrese el Procedimental"),
            conceptual: Yup.string().required("Ingrese el Conceptual"),
            tema: Yup.string().required("Ingrese el Tema"),
            planificacionMensualDetalleId: Yup.number().min(1, "Seleccione la unidad").required()
        });

    const handleInputChange = ( { target } ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : target.value
        });
    }
    
    useEffect(() => {
        const actualizarPlanificacionId = () => {
            setFormValues({...actaActiva.temaActivo});
        }
        actualizarPlanificacionId();
    }, [ actaActiva.temaActivo ]);

    ////// MANEO DE TEMAS
    const ValidarTema = (data) => {
        try {
            return validationSchemaTM.validateSync(data);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }
    const AddTema = () => {
        const data = {...formValues,
                      planificacionMensualDetalleId : actaActiva.unidadActiva.id,
                      id : actaActiva.temaActivo.id }

        if (ValidarTema(data))
            dispatch( startAddTema( data, (data.id > 0) ));    
    }
    const AddNewTema = () => {
        dispatch( AddNewTopic() )
    }
    const LoadTema = (id) => {
        dispatch( LoadTopic( id ) );
    }
    const DelTema = () => {       
        if (formValues.id > 0)
            dispatch( startDelTema( formValues.id ));    
    }
    return { LoadTema, AddNewTema, AddTema, ei,
             formValues, handleInputChange, actaActiva, DelTema };
}