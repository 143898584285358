import Swal from 'sweetalert2'
import './styles.css'

export const AltoContenedor = () => {
    let myHeight = 0;
 
    if (typeof (window.innerWidth) == 'number') {
        myHeight = window.innerHeight;
    } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        myHeight = document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        myHeight = document.body.clientHeight;
    }
    return myHeight;
 }

 export const Alert = (icon, text ) => {
    return Swal.fire({
                icon,
                text, 
                position: 'top-end',
                timer: 2000,
                showConfirmButton: false,
                toast: true,
                customClass: {
                    container: 'topSuperior',}
            });
}