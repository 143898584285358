import React from 'react';
import styled from "styled-components";

const StyledItem = styled.div`padding: 10px;
                              color: black;
                              border-radius: 3px;
                              margin: 3px;
                              cursor: pointer;
                              :hover { background: #f2f2f2; }`;

export const CustomItemRenderer = ({ item, itemIndex, props, state, methods }) => (
                                <StyledItem>
                                    <div onClick={() => methods.addItem(item)}>
                                    <input type="checkbox" defaultChecked={methods.isSelected(item)} />{" "}
                                    {item.descripcion}
                                    </div>
                                </StyledItem>
                            );