import { types } from '../types/types';

import { login, logout } from '../../servicios/JWTAuth';
import { getDataJwt } from "../../servicios/httpService";
import { AltoContenedor } from '../../helpers/functions'

export const startVerificarAcceso = ( data ) => {
    return async ( dispatch ) => {
        try {
            const status = await login(data);
            if ( status === 200) {
                dispatch( Autenticado(true));
                dispatch( Error(false));
            } else
                dispatch( Error(true));

        } catch (error) {}
    }
};

const Error = ( data ) => {
    return {type: types.autAutenticacionError,
            payload: data }
}

const Autenticado = ( data ) => {
    const jwt = getDataJwt();
    const altoContenedor = AltoContenedor();
    
    return { type: types.autAutenticado,
             payload: { autenticado : true, 
                        docente: parseInt(jwt.docente), 
                        nomUsuario: jwt.unique_name,
                        altoContenedor }} 
}

export const VerificarAutenticado = () => {
    const jwt = getDataJwt();
    const altoContenedor = AltoContenedor();
    
    return { type: types.autAutenticado,
             payload: { autenticado : jwt ? true : false, 
                        docente: jwt ? parseInt(jwt.docente) : -1, 
                        nomUsuario: jwt ? jwt.unique_name : "",
                        altoContenedor }}
    //return null;
}

export const Logout = () => {
    logout();
    
    return { type: types.autLogout }
}