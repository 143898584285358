import { types } from "../types/types";

const initialState = { autenticado: false,
                       nomUsuario: "",
                       altoContenedor: 0,
                       docente: -1,
                       error: false 
                    }

export const auth = ( state = initialState, action ) => {
    switch (action.type) {
        case types.autAutenticado:
            return { ...state,
                     autenticado: action.payload.autenticado,
                     docente: action.payload.docente,
                     altoContenedor: action.payload.altoContenedor,
                     nomUsuario: action.payload.nomUsuario };
        case types.autLogout:
            return { ...state,
                     autenticado: false,
                     docente: -1,
                     nomUsuario: ""  }
        case types.autAutenticacionError:
            return { ...state,
                     error: action.payload }
        default:
            return state;
    }
 }