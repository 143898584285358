import http from "./httpService";

export const login = async (data) => {
    try {
        const apiEndPoint = `/ControlAcceso/login`;
        let response = await http.post(apiEndPoint, data);
        
        if (response.status === 200 && response.data.token && response.data.expiration) {
            localStorage.setItem("access_token", response.data.token);
            localStorage.setItem("expire_at", response.data.expiration);

            return response.status;
        }
        return 400;
    } catch(e) {
        return 400;
    }
}

export const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
}