import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { startAddPlanificacionDiaria,
         startAddDetallePlanificacionDiaria,
         startDelDetallePlanificacionDiaria,
         AddNewActivity,
         LoadActivity } from '../state/actions/actionsPlanificacionDiaria';

const StateInitial = () => {
    const { actaPlaniDiaria } = useSelector( state => state.planificacionD );

    const stateInitial = {
        planificacionDiariaId :  actaPlaniDiaria.id, 
        id : 0, 
        categoria : '',
        titulo: '', 
        actividad : '', }
        
    return stateInitial;
}

export const usePlanificacionDetalle = ( initialState = StateInitial()) => {
    const dispatch = useDispatch();
    const { actaPlaniDiaria, actividades, actividadActiva } = useSelector( state => state.planificacionD );
    const [formValues, setFormValues] = useState(initialState);
	const categorias = [{ value:'Actividades Iniciales', label:'Actividades Iniciales'}, 
						{ value:'Desarrollo', label: "Desarrollo"},
						{ value: 'Actividades Finales', label: "Actividades Finales"},]

    const validationSchema = Yup.object({
        actividad: Yup.string().required("Ingrese la actividad"),
        titulo: Yup.string().required("Ingrese el titulo "),
        categoria: Yup.string().required("Selecciona la categoria"),
    });
    
    useEffect(() => {
        const actualizarPlanificacionId = () => {
            const e = { target: { 'name': 'planificacionDiariaId', 'value': actaPlaniDiaria.id}};
            
            handleInputChange( e );
        }
        actualizarPlanificacionId();
    }, [ actaPlaniDiaria ]);

    useEffect(() => {
        const LoadContent = () => {
            setFormValues({...actividadActiva,
                           planificacionDiariaId: actaPlaniDiaria.id,});
        }
        LoadContent();
    }, [ actividadActiva ]);
    ///////////////////////////////////////////////////////////
    const handleInputChange = ( { target } ) => {
        setFormValues({ ...formValues,
                        [ target.name ] : target.value });
    }

    const handleSelectChange = ( valor, name ) => {
        const e = { target: { 'name': name, 'value': valor }};
        handleInputChange( e )
    }

    const ValidarDetalle = () => {
        try {
            return validationSchema.validateSync(formValues);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }

    const AddDetalle = async ( data) => {      
        if (ValidarDetalle(formValues))
            if (actaPlaniDiaria.id === 0) {
                const datos = { ...data,
                                planificacionSemanalDetalleId: data.planificacionSemanalDetalleId[0].id };
            
                await dispatch( startAddPlanificacionDiaria( datos, formValues ) );
            } else {
                await dispatch( startAddDetallePlanificacionDiaria( formValues, (actividadActiva.id > 0) ) );
            }
    }

    /*const LimpiarControl = () => {
        console.log(formValues)
        setFormValues({
            ...initialState,
            'planificacionSemanalId' : formValues.planificacionSemanalId});}*/

    const DelActividad = () =>{
        
        if ( formValues.id > 0 )
            dispatch( startDelDetallePlanificacionDiaria( formValues.id ));
    }

    const Validar = ( ) => {
		// const validActa = data.id === 0 ? valid() : true;

		// if ( validActa && ValidarDetalle() )
		// 	setConfirmOpen(true)
	}
    
    const AddNewActividad = () => dispatch(AddNewActivity())
    
    const LoadActividad = (id) => dispatch( LoadActivity( id ) );

    return { LoadActividad, AddDetalle, AddNewActividad, ValidarDetalle, 
             formValues, handleInputChange, actaPlaniDiaria, categorias, actividades, DelActividad };
}