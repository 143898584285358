import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import ConfirmDialog from '../UI/ConfirmDialog'
import { Alert } from '../../helpers/functions';
import {usePlanificacionRevision} from '../../hooks/usePlanificacionRevisiones'

export const RevisionesUnidadDidactica = ({tipo}) => {
    const { Listar, docente, AddRevision, DeleteRevision, ApplyRevision } = usePlanificacionRevision(); 

	const [confirmOpen, setConfirmOpen] = useState(false);
	const [revision, setRevision] = useState("");

	const revisiones = Listar(tipo);

	const Validar = () => {
		if ( revision === "" )
            Alert( 'error', 'Ingrese la descripcion de la revision');
        else 
			setConfirmOpen(true)
	}

	return (
		<Grid container className="margenesGrid" >
			<Grid container component="main" style={{marginBottom:8, marginTop: 0}}>
				{ docente === 0 &&
				<>
				<Grid item xs={11} >
					<TextField	size="small"
								value={revision}
								onChange={ e => setRevision(e.target.value)}
								fullWidth
								multiline
								minRows={1}
								maxRows={5}
								label="Nueva Revision"/>
				</Grid>
				<Grid item xs={1} align="center">
					
						<Tooltip title="Agregar">
							<IconButton	edge="start"
										color="primary"
										aria-label="menu"
										style={{ padding: 4 }}
										onClick={ Validar }>
								<AddCircleIcon/>
							</IconButton>
						</Tooltip>
				</Grid>
				</>
				}
			</Grid>
			<Grid container style={{ maxHeight: 115, overflowX: 'hidden', overflowY: "auto"}}>
				{ revisiones.map( item =>
						<Grid container component="main"
							  key= {item.id.toString()} 
							  style={{marginBottom:8}} >
							<Grid item xs={11} style={{ margin:0 , paddingLeft: 0 }}>
								<TextField	size="small"
											fullWidth
											multiline
											minRows={1}
											maxRows={5}
											defaultValue={item.revision}
											disabled={true}/>
							</Grid>
							{ docente > 0 ?
							!item.aplicado && 
								<Grid item xs={1} align="center">
									<Tooltip title="Revision Aplicada">
										<IconButton	edge="start"
													color="default"
													aria-label="menu"
													style={{ padding: 4 }}
													onClick={() => ApplyRevision(tipo, item.id)}>
											<CheckCircleIcon/>
										</IconButton>
									</Tooltip>
								</Grid>
							:
							!item.aplicado && 
							<Grid item xs={1} align="center">
								<Tooltip title="Quitar Revision">
									<IconButton	edge="start"
												color="default"
												aria-label="menu"
												style={{ padding: 4 }}
												onClick={() => DeleteRevision(tipo, item.id)}>
										<RemoveCircleIcon/>
									</IconButton>
								</Tooltip>
							</Grid>
							}
						</Grid> 
					)
				}
			</Grid>
			<ConfirmDialog 
                title = {`¿Confirma registrar esta Revision?`} 
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { () => AddRevision( tipo, revision, setRevision ) } />
		</Grid>	
	);
};