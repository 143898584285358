import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from "yup";

import { Alert } from '../helpers/functions';
import { OpenModalPlani,
         startGradosDocente, 
         startDisciplinasDocenteGrado, 
         startCargarFamiliaValores,
         startPrincipiosValores,
         startAddPlanificacionMensual, 
         startAddIndicadorLogro, 
         startDeleteIndicadorLogro, 
         startUnidadesDidacticas,
         CerrarActaPlanificacion } from '../state/actions/actionsPlanificacion';

const StateInitial = () => {
    const { actaActiva } = useSelector( state => state.planificacion );

    const stateInitial = {  id : actaActiva.id,
                            docente : actaActiva.docente, 
                            grado : actaActiva.grado, 
                            disciplina : actaActiva.disciplina, 
                            periodoProgramado : actaActiva.periodoProgramado, 
                            fecha : moment( actaActiva.fecha).format('YYYY-MM-DD'),
                            familiaValores : actaActiva.familiaValores,
                            principiosValores : actaActiva.principiosValores,
                            fechaNoEPI : actaActiva.fechaNoEPI }

    return stateInitial;
}

const validationSchema = Yup.object({
                    fechaNoEPI: Yup.string().required("Ingrese la fechaNoEPI"),
                    principiosValores: Yup.array().min(1, "Selecciona al menos un principio valor"),
                    fecha: Yup.date().required().typeError("Ingrese fecha de entrega"),
                    periodoProgramado: Yup.string().required("Seleccione el mes programando"),
                    disciplina: Yup.number().required().min(1, "Seleccione la Disciplina"),
                    grado: Yup.number().required().min(1, "Seleccione el Grado"),
                    docente: Yup.number().min(1, "Seleccione un docente").required() });

export const usePlanificacion = ( initialState = StateInitial()) => {
    const dispatch = useDispatch();
    const { altoContenedor, docente } = useSelector( state => state.auth );
    const { openModalPlani, grados, disciplinas, actaActiva  } = useSelector( state => state.planificacion );
    const [ formValues, setFormValues ] = useState(initialState);
    const [ inicializado, setInicializado ] = useState(false);
    const [ ei, setEI ] = useState(actaActiva.id > 0 ? actaActiva.ei: grados.length > 0 ? grados[0].ei : false);

    const { indicadoresLogro, revisiones, unidades } = actaActiva
    const altoContenedorBase = altoContenedor - 150;

    const handleInputChange = ( { target } ) => {
        setFormValues({
            ...formValues,
            [ target.name ] : target.value
        });
    }
    
     useEffect(() => {
         return () => {
            dispatch( startUnidadesDidacticas(docente) );
         }
    }, []);
    
    useEffect(() => {
        const cargarGrados = () => {
            if ( actaActiva.id == 0 && formValues.docente > 0 )                
                dispatch( startGradosDocente( formValues.docente ) );
        }
        cargarGrados();
    }, [ formValues.docente ]);

    useEffect(() => {
        const cargarDisciplinas = () => {
            if ( actaActiva.id == 0 && formValues.docente > 0 && formValues.grado > 0 ) {
                dispatch( startDisciplinasDocenteGrado( formValues.docente, formValues.grado ) );
        }}
        cargarDisciplinas();
    }, [ formValues.grado ]);

    function setFamiliaValores () {
        setFormValues({
            ...formValues,
            familiaValores : [],
        });
        setPrincipiosValores ()
    }
    function setPrincipiosValores () {
        if (inicializado)
            setFormValues({
                ...formValues,
                principiosValores : []
        });
    }
    useEffect(() => {
        const cargarFamiliaValores = async () => {
            if ( actaActiva.id == 0 && formValues.periodoProgramado !== "" && actaActiva.id === 0 ){
                const valor = formValues.periodoProgramado.substring(0, (formValues.periodoProgramado.length - 5));
                
                await dispatch( startCargarFamiliaValores( valor ) )
                setFamiliaValores();
            }
        }
        cargarFamiliaValores();
    }, [ formValues.periodoProgramado ]);

    useEffect(() => {
        const cargarPrincipiosValores = () => {
            setPrincipiosValores();

            if ( formValues.familiaValores != null && formValues.familiaValores.length > 0 )
                dispatch( startPrincipiosValores( formValues.familiaValores ) );
        }
        cargarPrincipiosValores();
    }, [ formValues.familiaValores ]);
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    const ValidarActa = () => {
        try {
            return validationSchema.validateSync(formValues);
        } catch (err) {
            Alert( 'error', err.errors);
            return false;
        }
    }

    const NuevoIndicadorLogro = async ( indicadorLogro, setDato ) => {
        
        if ( actaActiva.id === 0 ){
            const gradoActivo = grados.filter(grado => grado.id === formValues.grado)
            const data = {...formValues, 
                          indicadoresLogro: [{id : 0, descripcion : indicadorLogro}]}
            
            dispatch( startAddPlanificacionMensual( data, gradoActivo[0].ei )).then(setDato(""));
        }else 
            dispatch( startAddIndicadorLogro( { planificacionMensualId: actaActiva.id, indicadorLogro } )).then(setDato(""));
    }
    
    const ActualizarPlanificacionMensual = async () => {
        if ( actaActiva.id > 0 ){
            const data = { ...formValues,
                            id : actaActiva.id }
            
            dispatch( startAddPlanificacionMensual( data ));
        }
    }

    const QuitarIndicadorLogro= ( id ) =>{
        if ( id > 0 )
            dispatch( startDeleteIndicadorLogro( id ));
    } 
    
    const UpdateOpenModalPlani = ( valor ) => {
        dispatch( CerrarActaPlanificacion() );
        dispatch( OpenModalPlani( valor ));
    }

    const ContenidoLength = () => unidades.length;

    const RevisionesNoAplicadasLength = () => revisiones.length == 0 ? 0 : revisiones.filter(x => x.aplicado == false).length; 


    return { altoContenedor, altoContenedorBase, UpdateOpenModalPlani, openModalPlani, indicadoresLogro,NuevoIndicadorLogro, QuitarIndicadorLogro, actaActiva, ValidarActa, formValues, handleInputChange,  disciplinas, ContenidoLength, RevisionesNoAplicadasLength, ei, setEI, ActualizarPlanificacionMensual};
}