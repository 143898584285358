import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import { TextShearch } from '../UI/UITextShearch'
import { DeleteButton } from '../UI/UIDeleteButton'

import { CerrarDetalleObservaciones, 
         startDeleteObservacion, 
         startUpdateObservacion } from '../../state/actions/actionsCalificaciones';

export const DetalleObservaciones = ( props ) => {
   const dispatch = useDispatch();
   const { altoContenedor } = useSelector(state => state.auth )
   const { observaciones } = useSelector(state => state.calificaciones )

   const [filtro, setFiltro] = useState( "" );
   
   useEffect(() => {
      return () => {
         dispatch( CerrarDetalleObservaciones() );
      }
   }, [])

   const UpdateObservacion = async ( observaciones, row ) => {
      if ( observaciones !== row.observaciones && row.id > 0 )
         dispatch( startUpdateObservacion({...row, observaciones }) );
   }

   const DeleteObservaciones = async (row) => {
      if ( row.id > 0 )
         dispatch( startDeleteObservacion( row ) );
   }
   return (
      <TableContainer style={{minHeight: `${altoContenedor  - 380}px`, maxHeight: `${altoContenedor  - 380}px`}} >
         <Table stickyHeader size="small" >
            <TableHead>
               <TableRow>
                  <TableCell align="left" style={{ backgroundColor: '#01579b', color : 'white', width: 200 }}>
                     <TextShearch filtro={filtro} setFiltro={setFiltro}/>
                  </TableCell>
                  <TableCell align="center" style={{ backgroundColor: '#01579b', color : 'white' }}>
                     Alumno
                  </TableCell>
                  <TableCell align="center" style={{ backgroundColor: '#01579b', color : 'white' }}>
                     Observaciones
                  </TableCell>
               </TableRow>
            </TableHead>
            <TableBody >
               { observaciones.map((row) => {
                  return ( <TableRow key={ Math.random()} 
                                     style={{ maxHeight:'10' }}
                                     className= { filtro.length === 0 ? '' : !row.alumno.includes( filtro.toUpperCase() ) ? 'hide' : '' } >
                              <TableCell colSpan={2} 
                                         className= {`${row.sexo}`} 
                                         style={{ padding:0, paddingLeft:10 }}>
                                 <DeleteButton functionDelete={DeleteObservaciones} 
                                               title = {'Quitar Alumno del Detalle'}
                                               titleComfirm={'¿Confirma quitar la observacion del listado?'}
                                               data={row}/>
                                 { row.alumno }
                              </TableCell>
                              <TableCell>
                                 <TextField defaultValue={row.observaciones} 
                                             maxLength={100}
                                             size = 'small'
                                             fullWidth = {true}
                                             onBlur = { e => UpdateObservacion( e.target.value, row ) }/>
                              </TableCell>
                        </TableRow>)
               }
            )}
            </TableBody>
         </Table>
      </TableContainer>
   )
}