import { types } from "../types/types";

const rowActaInicial = { añoLectivo: 0, 
                         id: 0,
                         idCorteEvaluativo: 0, 
                         corteEvaluativo: "",
                         idCurso: 0, 
                         curso: "", 
                         idProfesor: 0, 
                         docente: "",
                         ei: false,
                         extraordinario: false,
                         cerrado: false, };

const initialState = { actaActiva: rowActaInicial,
                       openModalCalif: false,
                       openModalObser: false,
                       calificaciones: [],
                       disciplinas: [],
                       cursos: [],
                       alumnos: [],
                       alumnosFueraLista: [],
                       observaciones: [],
                       conceptos: [],
                       indicadores: [],
                       notas: [] }

export const reducerCalificaciones = ( state = initialState, action ) => {
    switch (action.type) {
        /////// DATA
        case types.actaActivaInicial:
            return { ...state,
                     actaActiva: rowActaInicial };
        case types.dataAddActaCalificaciones:
            return { ...state,
                     calificaciones: [ ...state.calificaciones, action.payload ] }
        case types.actaActivaActualizar:
            return { ...state,
                     actaActiva: action.payload };
        case types.dataCargarCalificaciones:
            return { ...state,
                     calificaciones: action.payload};
        case types.dataCerrarActaCalificacion:
            return { ...state,
                     alumnos: [],
                     alumnosFueraLista: [],
                     conceptos: [],
                     indicadores: [],
                     notas: []};
        case types.dataCerrarCalificaciones:
            return initialState
        case types.dataAnularActaCalificacion:
            return { ...state,
                     calificaciones: state.calificaciones.filter( row => (row.id !== action.payload ) ) }
        case types.dataCargaCursoGuiado:
            return { ...state,
                     cursos: action.payload };
        case types.dataCargaDetalleObservaciones:
            return { ...state,
                     observaciones: action.payload.observaciones,
                     alumnosFueraLista: action.payload.alumnosFueraLista }
        case types.dataCerrarDetalleObservaciones:
            return { ...state,
                     observaciones: [],
                     alumnosFueraLista: [] }                     
        case types.dataUpdateObservacion:
            return { ...state,
                     observaciones: state.observaciones.map( row => row.id === action.payload.id ? action.payload : row )}
        case types.dataDeleteObservacion:
            return { ...state,
                     observaciones: state.observaciones.filter( row => (row.id !== action.payload.id)),
                     alumnosFueraLista :  [ ...state.alumnosFueraLista, {id : action.payload.id, descripcion : action.payload.alumno } ]    }
        case types.dataDeleteCalificacion:
            return { ...state,
                     alumnos: state.alumnos.filter( row => (row.idMatricula !== action.payload)),
                     notas: state.notas.filter( row => (row.idMatricula !== action.payload)) }
        case types.dataDisciplinasCursoDocente:
            return { ...state,
                     disciplinas: action.payload };
        case types.dataCursosDocente:
            return { ...state,
                     cursos: action.payload };
        case types.dataAlumnosActa:
            return { ...state,
                     alumnos: action.payload.alumnos,
                     alumnosFueraLista: action.payload.alumnosFueraActa,
                     notas: action.payload.notas,
                     conceptos: action.payload.conceptos,
                     indicadores: action.payload.indicadores, };
        //case types.dataUpdateCalificacion:
            //return { ...state,
            //         notas: state.notas.map( row => row.id === action.payload.id ? action.payload : row ) }
        /////// UI
        case types.uiOpenModalCalif:
            return { ...state,
                     openModalCalif: action.payload };
        case types.uiOpenModalObser:
            return { ...state,
                     openModalObser: action.payload };
        default:
            return state;
    }
 }