import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";

export const UICursos = ({ cursos, actaActiva, curso, handleInputChange, esEditable =  false }) => {
  const cursoActivo = cursos.filter(x => x.id === curso); 
  let nombreCurso = (cursoActivo.length > 0) ? cursoActivo[0].descripcion : "";

  const handleSelectChange = ( valor ) => {
    if ( valor.length > 0){
      const e = { target: { 'name': 'curso', 'value': valor[0].id}};

      handleInputChange( e );
    }
  }
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        Curso
      </Grid>
      {actaActiva > 0 && !esEditable ? (
        <TextField
          size="small"
          fullWidth
          multiline
          minRows={1}
          disabled={true}
          value={nombreCurso}
        />
      ) : (
        <Select
          values={cursoActivo}
          options={cursos}
          onChange={(valor) => handleSelectChange(valor)}
          valueField="id"
          labelField="descripcion"
          disabled={!(cursos.length > 0)}
        />
      )}
    </Grid>
  );
};