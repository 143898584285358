import { types } from '../types/types';
import { toast } from 'react-toastify';

import { getUnidadDidactica,
         getUnidadesDidacticas,
         postIndicadorLogro, 
         deleteIndicadorLogro,
         postPlanificacionMensual, 
         postAddUnidadPlanificacionMensual,
         postAddTemaPlanificacionMensual,
         deleteDetallePlanificacionMensual,
         deletePlanificacionMensual,
         deletePlanificacionSemanal,
         getUnidadesCursos,
         postPlanificacionSemanl,
         postDetallePlanificacionSemanal,
         deleteDetallePlanificacionSemanal,
         getPlanificacionSemanal,
         postPlanificacionMensualRevision,
         deletePlanificacionMensualRevision,
         updateAplicarPlanificacionMensualRevision,
         delUnidad,
         delTema,
         delContenido,
         postCerrarActaPM,
         postCerrarActaPS,
         getFamiliaValores,
         postContenidoUnidad,
         deleteContenidoUnidad,
         getContenidosUnidad,
         postPlanificacionSemanalRevision,
         deletePlanificacionSemanalRevision,
         updateAplicarPlanificacionSemanalRevision,
         getDetalleFecha,
         getPrincipiosValores,
         postAddFechaPlanificacionSemanal,
         delFechaPlanificacionSemanal,} from '../../servicios/planificacionService';
import { postCerrarActaPD } from '../../servicios/planificacionDiariaService';
import { getGradosDocente } from '../../servicios/gradosService';
import { getDisciplinasGradoDocente } from '../../servicios/disciplinasService';

const notify = (tipo, texto, corto)  => {
    let  duracion = corto ? 1500 : 5000;
    
    if ( tipo === 1 )
        toast.success( texto, { autoClose : duracion } );
    else
        toast.error( texto, { autoClose : duracion } );
};
////////////////////////////////////////////////////////////
export const OpenModalPlani = ( data ) => ({
    type: types.uiOpenModalPlani,
    payload: data
})
export const OpenModalPlaniSemanal = ( data ) => ({
    type: types.uiOpenModalPlaniSemanal,
    payload: data
})
export const OpenModalPlaniDiaria = ( data ) => ({
    type: types.uiOpenModalPlaniDiaria,
    payload: data
})
export const LoadVstUnidadDidactica = ( data ) => ({
    type: types.dataLoadVstUnidadDidactica,
    payload: data
})
// export const LoadAddDetalle = () => ({
//     type: types.dataLoadDetalle
// })
/////////////////////////////////////////////////////////
export const startCargarUnidadDidactica = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getUnidadDidactica(id);

            if ( status === 200 || status === 201 )
                dispatch( CargarUnidadDidactica( data ));
        } catch (err) {
            notify( 0, 'Se presento un error. ', true );
            console.log( err );
        }
    }
}
const CargarUnidadDidactica = ( data ) => ({
    type: types.dataCargarUnidadDidactica,
    payload: data
})
/////////////////////////////////////////////////////////
export const startCargarPlanificacionSemanal = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getPlanificacionSemanal(id);
            console.log("data PS", data)
            if ( status === 200 || status === 201 )
                dispatch( PlanificacionSemanal( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
const PlanificacionSemanal = ( data ) => ({
    type: types.dataCargarPlanificacionSemanal,
    payload: data
})
/////////////////////////////////////////////////////////
export const startDetalleFecha = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getDetalleFecha(id);
            
            if ( status === 200 || status === 201 )
                dispatch( CargarDetalleFecha( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
const CargarDetalleFecha = ( data ) => ({
    type: types.dataCargarDetalleFecha,
    payload: data
})

/////////////////////////////////////////////////////////
export const startLoadPlaniSemanal = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getUnidadesCursos(id);
            
            if ( status === 200 || status === 201 )
                dispatch( CargarUnidadesCursos( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
const CargarUnidadesCursos = ( data ) => ({
    type: types.dataCargarUnidadesCursos,
    payload: data
})
export const CargarTemasUnidad = ( unidad ) => ({
    type: types.dataCargarTemasUnidad,
    payload: unidad
})

/////////////////////////////////////////////////////////
export const startUnidadesDidacticas = (docente) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getUnidadesDidacticas(docente);

            if ( status === 200 || status === 201 )
                dispatch( ListarUnidadesDidacticas( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
const ListarUnidadesDidacticas = ( data ) => ({
    type: types.dataCargarUnidadesDidacticas,
    payload: data
})
export const Unload = () => ({
    type: types.dataCerrarPlanificacion,
})
/////////////////////////////////////////////////////////
export const startContenidosUnidad = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await getContenidosUnidad(id);

            if ( status === 200 || status === 201 )
                dispatch( ContenidosUnidad( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
const ContenidosUnidad = ( data ) => ({
    type: types.dataCargarContenidosUnidad,
    payload: data
})
/////////////////////////////////////////////////////////
export const startCerrarActaPM = (id) => {
    return async ( dispatch ) => {
        try {
            const { status }  = await postCerrarActaPM(id);

            if ( status === 200 || status === 201 )
                dispatch( CerrarActaPL( id ));
        } catch (err) {
            console.log( err );
        }
    }
}
const CerrarActaPL = ( data ) => ({
    type: types.dataCerrarActaPL,
    payload: data
})
/////////////////////////////////////////////////////////
export const startCerrarActaPS = (id) => {
    return async ( dispatch ) => {
        try {
            const { status }  = await postCerrarActaPS(id);

            if ( status === 200 || status === 201 )
                dispatch( CerrarActaPL( id ));
        } catch (err) {
            console.log( err );
        }
    }
}
/////////////////////////////////////////////////////////
export const startCerrarActaPD = (id) => {
    return async ( dispatch ) => {
        try {
            const { status, data }  = await postCerrarActaPD(id);

            if ( status === 200 || status === 201 )
                dispatch( CerrarActaPL( data ));
        } catch (err) {
            console.log( err );
        }
    }
}
/////////////////////////////////////////////////////////
export const startAddPlanificacionMensual = ( data, ei ) => {
    return async ( dispatch ) => {
        try {
            const id = data.id
            const { status, data: response }  = await postPlanificacionMensual( data );

            if ( status === 200 || status === 201 )
                if (id === 0) {
                    dispatch( AddActaListaPlanificacionMensual( response ));
                    dispatch( ActualizarEI( ei ));
                } else
                    dispatch( UpdActaListaPlanificacionMensual( response ));
            else
                notify( 0, 'Se presento un error registrando la Unidad Didactica, verifique si exista una registrada. ', true );

        } catch (err) {
            console.log( err );
        }
    }
}
const AddActaListaPlanificacionMensual = ( data ) => ({
    type: types.dataAddActaListaPlanificacionMensual,
    payload: data
})
const UpdActaListaPlanificacionMensual = ( data ) => ({
    type: types.dataUpdActaListaPlanificacionMensual,
    payload: data
})
const ActualizarEI = ( data ) => ({
    type: types.dataActualizarEI,
    payload: data
})
////////////////////////////////////////////////////////////
export const startAddPlanificacionSemanal = ( data, detalle ) => {
    return async ( dispatch ) => {
        try {
            const { status, data: response }  = await postPlanificacionSemanl( data );

            if ( status === 200 || status === 201 ){
                dispatch( ActualizarPlanificacionSemanalActiva( response ));
                
                detalle.planificacionSemanalId = response.id;
                dispatch(startAddDetallePlanificacionSemanal(detalle))
            }
        } catch (err) {
            console.log( err );
        }
    }
}
export const ActualizarPlanificacionSemanalActiva = ( data ) => ({
    type: types.dataPlanificacionSemanalActiva,
    payload: data
})
////////////////////////////////////////////////////////////
export const startUpdPlanificacionSemanal = ( data ) => {
    return async ( dispatch ) => {
        try {
            const { status, data: response }  = await postPlanificacionSemanl( data );

            if ( status === 200 || status === 201 )
                dispatch( UpdPlanificacionSemanal( response ));
        } catch (err) {
            console.log( err );
        }
    }
}
const UpdPlanificacionSemanal = ( data ) => ({
    type: types.dataUpdPlanificacionSemanal,
    payload: data
})
////////////////////////////////////////////////////////////
export const startCargarFamiliaValores = ( periodo ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getFamiliaValores( periodo );

            if ( status === 200)
                dispatch( FamiliaValores( data ));
        } catch (err) {}
    }
}
const FamiliaValores = ( data ) => ({
    type: types.dataFamiliaValores,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startPrincipiosValores = ( familiaValor ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getPrincipiosValores( familiaValor );

            if ( status === 200)
                dispatch( PrincipiosValores( data ));
        } catch (err) {}
    }
}
const PrincipiosValores = ( data ) => ({
    type: types.dataPrincipiosValores,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startGradosDocente = ( docente ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getGradosDocente( docente );

            if ( status === 200)
                dispatch( GradosDocente( data ));
        } catch (err) {}
    }
}
const GradosDocente = ( data ) => ({
    type: types.dataGradosDocente,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDisciplinasDocenteGrado = ( docente, grado ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await getDisciplinasGradoDocente( docente, grado );    

            if ( status === 200 ) {
                dispatch( DisciplinasDocenteGrado( data ));
            }
        } catch (err) {
            console.log("err", err)
        }
    }
}
const DisciplinasDocenteGrado = ( data ) => ({
    type: types.dataDisciplinasGradoDocente,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startAddContenidoUnidad = ( contenido ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postContenidoUnidad( contenido );
            if ( status === 200 )
                dispatch( AddContenidoUnidad( data ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AddContenidoUnidad = ( data ) => ({
    type: types.AddContenidoUnidad,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDeleteContenidoUnidad = ( contenido ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deleteContenidoUnidad( contenido );    

            if ( status === 200)
                dispatch( DeleteContenidoUnidad( contenido ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeleteContenidoUnidad = ( data ) => ({
    type: types.dataDeleteContenidoUnidad,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startAddIndicadorLogro = ( indicador ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postIndicadorLogro( indicador );    

            if ( status === 200)
                dispatch( AddIndicadorLogro( data ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AddIndicadorLogro = ( data ) => ({
    type: types.dataAddIndicadorLogro,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDeleteIndicadorLogro = ( indicador ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deleteIndicadorLogro( indicador );    

            if ( status === 200)
                dispatch( DeleteIndicadorLogro( indicador ));

        } catch (err) {
            console.log(err)
        }
    }
}
const DeleteIndicadorLogro = ( data ) => ({
    type: types.dataDeleteIndicadorLogro,
    payload: data 
})
///////////////////////////////////////////////////////////////
export const startDeleteDetallePlanificacionSemanal = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deleteDetallePlanificacionSemanal( id );    

            if ( status === 200)
                dispatch( DeleteDetallePlanificacionSemanal( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeleteDetallePlanificacionSemanal = ( data ) => ({
    type: types.dataDeleteDetallePlanificacionSemanal,
    payload: data 
})
////////////////////////////////////////////////////////////
/*export const  startAddDetallePlanificacionMensual = ( detalle, contenido ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postDetallePlanificacionMensual( detalle );    
            
            if ( status === 200){
                const datos = { planificacionMensualDetalleId : data.id,
                                contenido }
                await dispatch( AddDetallePlanificacionMensual( data ));
                
                await dispatch(startAddContenidoUnidad(datos))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
const AddDetallePlanificacionMensual = ( data ) => ({
    type: types.dataAddDetallePlanificacionMensual,
    payload: data 
})*/
////////////////////////////////////////////////////////////
export const  startAddUnidad = ( detalle, actualizacion ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postAddUnidadPlanificacionMensual( detalle );    

            if ( status === 200)
                if (actualizacion)
                    await dispatch( UpdUnidad( data ));
                else
                    await dispatch( AddUnidad( data ));
                
        } catch (err) {
            console.log(err)
        }
    }
}
const AddUnidad = ( data ) => ({
    type: types.dataAddUnidad,
    payload: data 
})
const UpdUnidad = ( data ) => ({
    type: types.dataUpdUnidad,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDelUnidad = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await delUnidad( id );    

            if ( status === 200)
                dispatch( DelUnidad( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DelUnidad = ( data ) => ({
    type: types.dataDelUnidad,
    payload: data 
})
//////////////////////////////////////////////////////
export const startDelTema = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await delTema( id );    

            if ( status === 200)
                dispatch( DelTema( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DelTema = ( data ) => ({
    type: types.dataDelTema,
    payload: data 
})
//////////////////////////////////////////////////////
export const startDelContenido = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await delContenido( id );    

            if ( status === 200)
                dispatch( DelContenido( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DelContenido = ( id ) => ({
    type: types.dataDelContenido,
    payload: id 
})
////////////////////////////////////////////////
export const AddNewUnit = () => ({
    type: types.dataAddNewUnit
})
export const LoadUnit = (id) => ({
    type: types.dataLoadUnit,
    payload: id
})
////////////////////////////////////////////////////////////
export const  startDelFechaPlanificacionSemanal = ( data ) => {
    return async ( dispatch ) => {
        try {
            console.log(data)
            const { status } = await delFechaPlanificacionSemanal( data.id );    

            if ( status === 200)
                await dispatch( delFecha( data ));
                
        } catch (err) {
            console.log(err)
        }
    }
}
const delFecha = ( data ) => ({
    type: types.dataDelDate,
    payload: data 
})
////////////////////////////////////////////////////////////
export const  startAddFechaPlanificacionSemanal = ( datos ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postAddFechaPlanificacionSemanal( datos );    

            if ( status === 200)
                    await dispatch( AddFecha( data ));
                
        } catch (err) {
            console.log(err)
        }
    }
}
const AddFecha = ( data ) => ({
    type: types.dataAddDate,
    payload: data 
})
////////////////////////////////////////////////////////////
export const  startAddTema = ( detalle, actualizacion ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postAddTemaPlanificacionMensual( detalle );    

            if ( status === 200)
                if (actualizacion)
                    await dispatch( UpdTema( data ));
                else
                    await dispatch( AddTema( data ));
                
        } catch (err) {
            console.log(err)
        }
    }
}
const AddTema = ( data ) => ({
    type: types.dataAddTopic,
    payload: data 
})
const UpdTema = ( data ) => ({
    type: types.dataUpdTopic,
    payload: data 
})
export const AddNewTopic = () => ({
    type: types.dataAddNewTopic
})
export const LoadTopic = (id) => ({
    type: types.dataLoadTopic,
    payload: id
})
export const AddNewContent = () => ({
    type: types.dataAddNewContent
})
export const LoadContent = (id) => ({
    type: types.dataLoadContent,
    payload: id
})
////////////////////////////////////////////////////////////
export const startAddDetallePlanificacionSemanal = ( detalle, actualizar ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postDetallePlanificacionSemanal( detalle ); 

            if ( status === 200 || status === 201 )
                if (actualizar)
                    dispatch( UpdDetallePlanificacionSemanal( data ));
                else
                    dispatch( AddDetallePlanificacionSemanal( data ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AddDetallePlanificacionSemanal = ( data ) => ({
    type: types.dataAddContent,
    payload: data 
})
const UpdDetallePlanificacionSemanal = ( data ) => ({
    type: types.dataUpdContent,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDeleteDetallePlanificacionMensual = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deleteDetallePlanificacionMensual( id );    

            if ( status === 200)
                dispatch( DeleteDetallePlanificacionMensual( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeleteDetallePlanificacionMensual = ( data ) => ({
    type: types.dataDeleteDetallePlanificacionMensual,
    payload: data 
})
////////////////////////////////////////////////////////////
export const CerrarActaPlanificacion = () => ({
    type: types.dataCerrarActaPlanificacion,
})
export const CerrarActaPlanificacionSemanal = () => ({
    type: types.dataCerrarActaPlanificacionSemanal,
})
export const CerrarActaPlanificacionDiaria = () => ({
    type: types.dataCerrarActaPlanificacionDiaria,
})
///////////////////////////////////////////////////////////
export const startAnularActaPlanificacion = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deletePlanificacionMensual( id );    

            if ( status === 200)
                dispatch( DeletePlanificacionMensual( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeletePlanificacionMensual = ( data ) => ({
    type: types.dataDeletePlanificacionMensual,
    payload: data 
})
///////////////////////////////////////////////////////////
export const startAnularActaPlanificacionSemanal = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deletePlanificacionSemanal( id );    

            if ( status === 200)
                dispatch( DeletePlanificacionSemanal( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeletePlanificacionSemanal = ( data ) => ({
    type: types.dataDeletePlanificacionMensual,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startAddRevisionUnidadDidactica = ( datos ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postPlanificacionMensualRevision( datos );    

            if ( status === 200)
                dispatch( AddRevisionPlanificacionMensual( data ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AddRevisionPlanificacionMensual = ( data ) => ({
    type: types.dataAddRevisionPlanificacionMensual,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDeleteRevisionUnidadDidactica = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deletePlanificacionMensualRevision( id );    

            if ( status === 200)
                dispatch( DeleteRevisionPlanificacionMensual( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeleteRevisionPlanificacionMensual = ( id ) => ({
    type: types.dataDeleteRevisionPlanificacionMensual,
    payload: id 
})
////////////////////////////////////////////////////////////
export const startApplyRevisionUnidadDidactica = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await updateAplicarPlanificacionMensualRevision( id );    

            if ( status === 200)
                dispatch( UpdateRevisionPlanificacionMensual( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const UpdateRevisionPlanificacionMensual = ( id ) => ({
    type: types.dataUpdateRevisionPlanificacionMensual,
    payload: id 
})
////////////////////////////////////////////////////////////
export const startAddRevisionPlanificacionSemanal = ( datos ) => {
    return async ( dispatch ) => {
        try {
            const { status, data } = await postPlanificacionSemanalRevision( datos );    

            if ( status === 200)
                dispatch( AddRevisionPlanificacionSemanal( data ));
        } catch (err) {
            console.log(err)
        }
    }
}
const AddRevisionPlanificacionSemanal = ( data ) => ({
    type: types.dataAddRevisionPlanificacionSemanal,
    payload: data 
})
////////////////////////////////////////////////////////////
export const startDeleteRevisionPlanificacionSemanal = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await deletePlanificacionSemanalRevision( id );    

            if ( status === 200)
                dispatch( DeleteRevisionPlanificacionSemanal( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const DeleteRevisionPlanificacionSemanal = ( id ) => ({
    type: types.dataDeleteRevisionPlanificacionSemanal,
    payload: id 
})
////////////////////////////////////////////////////////////
export const startApplyRevisionPlanificacionSemanal = ( id ) => {
    return async ( dispatch ) => {
        try {
            const { status } = await updateAplicarPlanificacionSemanalRevision( id );    

            if ( status === 200)
                dispatch( UpdateRevisionPlanificacionSemanal( id ));
        } catch (err) {
            console.log(err)
        }
    }
}
const UpdateRevisionPlanificacionSemanal = ( id ) => ({
    type: types.dataUpdateRevisionPlanificacionSemanal,
    payload: id 
})