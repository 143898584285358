import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "react-dropdown-select";

export const UIDiciplinas = ({ disciplinas, actaActiva, disciplina, handleInputChange, listaVisible = false }) => {
  let disciplinaActiva = [];
  
  if (disciplinas != null)
    disciplinaActiva = disciplinas.filter( x => x.id === disciplina );
  
  const nombreDisciplina = actaActiva > 0 && disciplinaActiva.length > 0 ? disciplinaActiva[0].descripcion : "";
  
  const handleSelectChange = ( valor ) => {
    if ( valor.length > 0) {
      const e = { target: { 'name': 'disciplina', 'value': valor[0].id}};
      handleInputChange( e );
    }
  }
  return (
    <Grid item xs={12} >
      <Grid item xs={12} >
        Disciplina
      </Grid>

      {actaActiva > 0 && listaVisible === false ? (
        <TextField
          size="small"
          fullWidth={true}
          disabled={true}
          defaultValue={nombreDisciplina}/>
      ) : (
        <Select
          options={disciplinas}
          values={disciplinaActiva}
          valueField="id"
          labelField="descripcion"
          searchBy="descripcion"
          onChange={ valor => handleSelectChange(valor) }
          disabled={!(disciplinas.length > 0)}/>
      )}
    </Grid>
  );
};