import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { UIBotonera } from '../../UI/UIBotoneras'
import {usePlanificacionDetalleTema} from '../../../hooks/usePlanificacionDetalleTema'

import '../style.css';

export const AddTema = () => {
	const { LoadTema, AddNewTema, AddTema, actaActiva, formValues, handleInputChange, ei, DelTema} = usePlanificacionDetalleTema(); 
	
	const { tema, procedimental, conceptual, actitudinal, tecnicaEvaluacion, evidenciaAprendizaje, criterioEvaluacion, horasClase, fechaInicio, fechaFinalizacion} = formValues;

	const [verDetalle, setVerDetalle] = useState(true);

	return (
		<Grid container className="margenesGrid" >
			<Grid item xs={1} className="margenesGrid subtitulo" >
            	{`Temas`} 
			</Grid>
			<Grid item xs={1}  align="center" >
				<Tooltip title={verDetalle ? "Contraer" : "Expandir"}>
					<IconButton	edge="start"
								color="inherit"
								aria-label="menu"
								style={{ padding: 4 }}
								onClick={() => setVerDetalle( !verDetalle )}>
						{ verDetalle ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
					</IconButton>
				</Tooltip>
			</Grid>
						
			<Grid container component="main">
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}				
								fullWidth
								label="Tema"
								value={tema}
								name="tema"
								onChange={ e => handleInputChange(e)}/>
				</Grid>
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Conceptual"
								value={conceptual}
								name="conceptual"
								onChange={ e => handleInputChange( e )}/>
				</Grid>
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Procedimental"
								value={procedimental}
								name="procedimental"
								onChange={ e => handleInputChange( e )}/>
				</Grid>
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Actitudinal"
								value={actitudinal}
								name="actitudinal"
								onChange={ e => handleInputChange( e )}/>
				</Grid>
				{ ei ? <>
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Tecnica Evaluacion"
								value={tecnicaEvaluacion}
								name="tecnicaEvaluacion"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}						
								fullWidth
								label="Evidencia Aprendizaje"
								value={evidenciaAprendizaje}
								name="evidenciaAprendizaje"
								onChange={ e => handleInputChange( e)}/>
				</Grid></>
				:
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Criterio Evaluacion"
								value={criterioEvaluacion}
								name="criterioEvaluacion"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
				}
				<Grid item xs={6} md={2} className="margenesGrid">
					<TextField	size="small"
								type="date"
								fullWidth
								label="Fecha/Inicio"
								value={moment(fechaInicio).format('YYYY-MM-DD')}
								name="fechaInicio"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
				<Grid item xs={6} md={2} className="margenesGrid">
					<TextField	size="small"
								type="date"
								fullWidth
								label="Fecha/Final"
								value={moment(fechaFinalizacion).format('YYYY-MM-DD')}
								name="fechaFinalizacion"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
				<Grid item xs={2} md={1} className="margenesGrid">
					<TextField	size="small"
								type="number"							
								fullWidth
								label="H/C"
								value={horasClase}
								name="horasClase"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
				<Grid item xs={ ei ? 7 : 11} style={{textAlign:'center'}}>
					<UIBotonera lista = { actaActiva.temas }
								functionLoad = { LoadTema }
								functionAddNew = { AddNewTema }
								functionAdd = { AddTema }
								functionDel ={ DelTema }
								titleButton = "Tema"
								opcion = {2} 
								unidad = {actaActiva.unidadActiva.id}/>
				</Grid>
			</Grid>
		</Grid>	
	);
};