import React, { useState} from 'react'
import Button from '@material-ui/core/Button';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Tooltip from '@material-ui/core/Tooltip';

import ConfirmDialog from '../UI/ConfirmDialog'

export const AddButton = ({ functionAdd, titleButton, titleConfirm}) => {
    const [confirmOpen, setConfirmOpen] = useState( false );

    const ConfirmAdd = () => setConfirmOpen( true );

    return (
        <>
            <Tooltip title={ titleButton }>
                <Button variant="contained" 
                        color="primary" 
                        fullWidth={true}
                        onClick={ ConfirmAdd }>
                        <PostAddIcon/>
                </Button>
            </Tooltip>
            <ConfirmDialog title = { titleConfirm} 
                open = {confirmOpen} 
                setOpen = {setConfirmOpen} 
                onConfirm = { () => functionAdd() } />
        </>
    )
}
