import http from "./httpService";
import { getDataJwt } from "./httpService";

/////PLANIFICACION DIARIA
export function getPlanificacionDiaria( id ) {
    const apiEndPoint = `/PlanificacionDiaria/${ id }`;

    return http.get(apiEndPoint);
}
export function getContenidos( id ) {
    const apiEndPoint = `/PlanificacionDiaria/Contenido/${ id }`;

    return http.get(apiEndPoint);
}
export function postPlanificacionDiaria( data ) {
    const { usuario } = getDataJwt();
    
    data.usuario = parseInt(usuario);

    const apiEndPoint = `/PlanificacionDiaria`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function postDetallePlanificacionDiaria( data ) {
    const apiEndPoint = `/PlanificacionDiaria/Detalle`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function postCerrarActaPD( acta ) {
    const { usuario } = getDataJwt();
    
    const data = { "acta" : parseInt(acta),
                   "usuario" : parseInt(usuario) };

    return http.post(`/PlanificacionDiaria/CerrarActa`, data )
               .then(resp => resp)
               .catch(err => err);
}

export function delDetallePlanificacionDiaria( id ) {
    const apiEndPoint = `/PlanificacionDiaria/DelDetalle/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function postRevision( data ) {
    const { usuario } = getDataJwt();
    
    data.usuario = parseInt(usuario);

    const apiEndPoint = `/PlanificacionDiaria/AddRevision`;

    return http.post(apiEndPoint,  data )
               .then(resp => resp)
               .catch(err => err);
}
export function delRevision( id ) {
    let apiEndPoint = `/PlanificacionDiaria/DelRevision/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
export function updRevision( id ) {
    const apiEndPoint = `/PlanificacionDiaria/UpdRevision/${id}`;

    return http.get(apiEndPoint )
               .then(resp => resp)
               .catch(err => err);
}
