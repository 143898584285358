import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import { AddTema } from './AddTema';
import { UIBotonera } from '../../UI/UIBotoneras'
import { usePlanificacionDetalle} from '../../../hooks/usePlanificacionDetalle'
import '../style.css';

export const AddDetalleUnidadDidactica = () => {
	const { LoadUnidad, AddNewUnidad, AddUnidad, actaActiva, formValues, handleInputChange, DelUnidad} = usePlanificacionDetalle(); 

	const { unidad, competenciaGrado, ejeTranversal, observaciones } = formValues;
    
	return (
        <Grid container spacing={1} component="main">
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Unidad"
								value={unidad}
								name="unidad"
								onChange={ e => handleInputChange( e )}/>
				</Grid>
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								label="Competencia de Grado"
								fullWidth
								value={competenciaGrado}
								name="competenciaGrado"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
				<Grid item xs={12} md={4} className="margenesGrid">
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Eje Tranversal"
								value={ejeTranversal}
								name="ejeTranversal"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
				<Grid item xs={12}>
					<TextField	size="small"
								multiline
								minRows={1}
								maxRows={5}
								fullWidth
								label="Observaciones"
								value={observaciones}
								name="observaciones"
								onChange={ e => handleInputChange( e)}/>
				</Grid>
			<Grid item xs={12} style={{textAlign:'center'}}>
				<UIBotonera lista = { actaActiva.unidades }
							opcion = {1} 
							functionLoad = {LoadUnidad }
							functionAddNew = {AddNewUnidad}
							functionAdd = { AddUnidad }
							functionDel = {DelUnidad}/>
			</Grid>
			{<Grid item xs={12} className="margenesGrid">
				{(actaActiva.unidadActiva != null && actaActiva.unidadActiva.id > 0) && <AddTema/>}
			</Grid>}
		</Grid>
    );
};