import React, { useEffect } from 'react';
import { BrowserRouter, 
         Switch,
         Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

import { Home } from '../components/home';
import { Login } from '../components/auth/login';
import { Navbar } from '../components/UI/NavBar';
import { Planificacion } from '../components/planificacion/Planificacion';
import { Calificaciones } from '../components/calificaciones/Calificaciones';
import { Reportes } from '../components/reportes/ResumenCalificaciones'

import { VerificarAutenticado } from '../state/actions/auth';
import { startCargaInicial } from '../state/actions/actions';
import "react-toastify/dist/ReactToastify.css";

export const AppRouter = () => {
    const dispatch = useDispatch();
    const { autenticado, docente } = useSelector( state => state.auth );

    useEffect(() => {
        dispatch( VerificarAutenticado() );
    }, [ dispatch ])
    
    useEffect(() => {
        if ( autenticado ) {
            dispatch( startCargaInicial( docente ) );
        }
    }, [autenticado, dispatch, docente]);
    
    return (
        <> 
            <BrowserRouter>
                { autenticado && <Navbar/> }
                <Switch>
                    <PublicRoute 
                        exact 
                        path="/login" 
                        component={ Login }
                        isAuthenticated={ autenticado }/>

                    <PrivateRoute 
                        exact 
                        path="/calificaciones" 
                        component={ Calificaciones } 
                        isAuthenticated={ autenticado }/>
                    
                    <PrivateRoute 
                        exact 
                        path="/planificacion" 
                        component={ Planificacion } 
                        isAuthenticated={ autenticado }/>
                    
                    <PrivateRoute 
                        exact 
                        path="/reportes" 
                        component={ Reportes } 
                        isAuthenticated={ autenticado }/>

                    <PrivateRoute 
                        exact 
                        path="/" 
                        component={ Home } 
                        isAuthenticated={ autenticado }/>

                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
            <ToastContainer/>
        </>
    )
}
/*

                    
                    */