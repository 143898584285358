import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from "react-dropdown-select";
import { useSelector } from 'react-redux';

export const UICortes = ( { corte = 0, actaActiva, handleInputChange } ) => {
  const { cortes } = useSelector( state => state.general );

  const corteActivo = cortes.filter(x => x.id === corte);
  const nombreCorte = corteActivo.length > 0 ? corteActivo[0].descripcion : "";

  const handleSelectChange = ( valor ) => {
    if (valor.length > 0) {
      const e = { target: { 'name': 'corte', 'value': valor[0].id}};
      handleInputChange( e )
    }
  }
  return (
    <Grid item xs={12} >
      <Grid item xs={12} >
        Corte Evaluativo
      </Grid>
      {actaActiva > 0 ? (
        <TextField
          size="small"
          disabled={true}
          fullWidth={true}
          defaultValue={ nombreCorte }
        />
      ) : (
        <Select
          options={cortes}
          onChange={ valor => handleSelectChange(valor) }
          values={corteActivo}
          valueField="id"
          labelField="descripcion"
          searchBy="descripcion"
          disabled={!(cortes.length > 0)}
        />
      )}
    </Grid>
  );
};